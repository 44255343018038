import React, { useEffect, useState } from "react";
import { db } from "../../firebase"; // Assuming you're using Firebase
import {
  collection,
  getDocs,
  addDoc,
  doc,
  arrayUnion,
  setDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";
import SearchableDropdown from "../../components/fields/SearchableDropdown";
import { Controller, useForm } from "react-hook-form";

const FProgram = () => {
  const [searchCriteria, setSearchCriteria] = useState({
    programType: "", // Default to 'Personal Loan'
    basicSalary: 0, // Default to 9000
    financialAmount: 0, // Default to 60000
  });

  const navigate = useNavigate();

  // Modal state
  const { control } = useForm();
  const [showModal, setShowModal] = useState(false);
  const [loanId, setLoanId] = useState(null);
  const [documents, setDocuments] = useState({
    bank_statement: null,
    dbr_ratio: null,
    salary_definition: null,
  });
  const [loading, setLoading] = useState(false);

  const [loanPrograms, setLoanPrograms] = useState([]);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [referral, setReferral] = useState([]);
  const [selectedReferral, setSelectedReferral] = useState(null);

  const [programTypes, setProgramType] = useState([]);
  const [selectedProgramType, setSelectedProgramType] = useState("");

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsCollectionRef = collection(db, "Clients");
        const clientSnapshot = await getDocs(clientsCollectionRef);
        const clientsData = clientSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClients(clientsData); // Set fetched clients
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, []);

  console.log("clients", clients);

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const referralsCollectionRef = collection(db, "ReferralCompany");
        const referralSnapshot = await getDocs(referralsCollectionRef);
        const referralsData = referralSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReferral(referralsData); // Set fetched clients
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchReferrals();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDocumentChange = (e) => {
    const { name, files } = e.target;
    setDocuments((prevDocs) => ({
      ...prevDocs,
      [name]: files[0],
    }));
  };

  const handleClientChange = (selectedOption) => {
    // Console log the entire selectedOption to see its structure
    console.log("Selected Option:", selectedOption);

    const selectedClientId = selectedOption || selectedOption?.value;
    const client = clients.find((client) => client.id === selectedClientId);

    // Console log the found client
    console.log("Found Client:", client);

    // Console log the specific monthlyIncome
    console.log("Monthly Income:", client?.monthlyIncome);

    setSelectedClient(client);

    setSearchCriteria((prev) => {
      const newValue = client ? client.monthlyIncome : prev.basicSalary;
      // Console log the value being set
      console.log("Setting basicSalary to:", newValue);

      return {
        ...prev,
        basicSalary: newValue,
      };
    });
  };
  useEffect(() => {
    const fetchProgramType = async () => {
      try {
        const programTypesCollectionRef = collection(db, "ProgramType");
        const programTypeSnapshot = await getDocs(programTypesCollectionRef);
        const programTypesData = programTypeSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProgramType(programTypesData);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };

    fetchProgramType();
  }, []);

  const findLoanPrograms = async () => {
    try {
      const loanProgramsRef = collection(db, "loanPrograms");
      const querySnapshot = await getDocs(loanProgramsRef);

      if (querySnapshot.empty) {
        setLoanPrograms([]);
        return;
      }

      // Move the programs declaration before trying to use it
      const programs = querySnapshot.docs.map((doc) => doc.data());

      console.log("prograammsss", programs);

      const filteredPrograms = programs.filter((program) => {
        const programTypeMatches =
          !searchCriteria.programType || // If no program type is selected, don't filter by it
          program.program_type === searchCriteria.programType;
        const salaryMatches =
          parseInt(
            program.financial_details.financing_limits?.minimum_financing
          ) <= parseInt(searchCriteria.basicSalary);
        const loanAmountMatches =
          parseInt(
            program.financial_details.financing_limits?.maximum_financing
          ) >= parseInt(searchCriteria.financialAmount);

        return programTypeMatches && salaryMatches && loanAmountMatches;
      });

      if (filteredPrograms.length === 0) {
        setError("No loan programs match your criteria.");
      } else {
        setError(null);
      }

      setLoanPrograms(filteredPrograms);
    } catch (error) {
      console.error("Error fetching loan programs:", error);
      setError(
        "There was an error fetching the loan programs. Please try again later."
      );
    }
  };

  const handleReferralChange = (e) => {
    const selectedReferralId = e.target.value;
    const referal = referral.find(
      (referal) => referal.id === selectedReferralId
    );
    setSelectedReferral(referal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    console.log("Searching with criteria:", searchCriteria);
    findLoanPrograms();
  };

  // const handleApply = async (program) => {
  //   setShowModal(true);
  //   if (!selectedClient) {
  //     alert("Please select a client first.");
  //     return;
  //   }

  //   try {
  //     // Step 1: Get the current count of loan applications from the database
  //     const appliedLoanRef = collection(db, "AppliedLoanApplication");
  //     const snapshot = await getDocs(appliedLoanRef);
  //     const applicationCount = snapshot.size; // Count the number of existing applications

  //     // Step 2: Generate the loan application ID
  //     const serialNumber = (applicationCount + 1).toString().padStart(2, "0"); // Serial number with leading zeros (e.g., 01, 02, ...)
  //     const loanId = `CBF-AP-${serialNumber}`;

  //     // Step 3: Create the loan application data
  //     const loanApplicationData = {
  //       userId: selectedClient.id,
  //       userName: selectedClient.name,
  //       nationalID: selectedClient.nationalID,
  //       contact: selectedClient.contact,
  //       email: selectedClient.email,
  //       employmentStatus: selectedClient.employmentStatus,
  //       employeeAge: selectedClient.age,
  //       loanProgram: program.baseFields.programName,
  //       bank: program.baseFields.selectedBank || "Unknown Bank",
  //       eligibilityStatus: "In Progress",
  //       approvalStatus: "In Progress",
  //       approvalDate: new Date().toISOString(),
  //       loanAmount: searchCriteria.financialAmount,
  //       creditLimit: program.baseFields.loanDetails.maxLoanAmount,
  //       interestRate: program.baseFields.loanDetails.interestRate || 5.5,
  //       loanId: loanId,
  //     };

  //     // Step 4: Add document to AppliedLoanApplication collection
  //     const docRef = await addDoc(appliedLoanRef, loanApplicationData);

  //     console.log("Loan application submitted with ID:", loanId);
  //     setLoanId(loanId);

  //     // Step 5: Update the totalLoanAmount in LoanStatistics
  //     const bankName = program.baseFields.selectedBank || "Unknown Bank";
  //     const loanStatsRef = doc(db, "LoanStatistics", "AABCNV6rull84tAwtRS6"); // Fixed document ID
  //     const loanStatsSnapshot = await getDoc(loanStatsRef);

  //     if (loanStatsSnapshot.exists()) {
  //       // If the document exists, update the specific bank's statistics
  //       const existingData = loanStatsSnapshot.data();
  //       const bankStatsKey = `bankStatistics.${bankName}`;

  //       await updateDoc(loanStatsRef, {
  //         totalApplications: (existingData.totalApplications || 0) + 1,
  //         [bankStatsKey]: {
  //           totalApplications: (existingData.bankStatistics?.[bankName]?.totalApplications || 0) + 1,
  //           approvedLoans: existingData.bankStatistics?.[bankName]?.approvedLoans || 0,
  //           totalLoanAmount: Number(existingData.bankStatistics?.[bankName]?.totalLoanAmount || 0) + Number(searchCriteria.financialAmount), // Convert to number and add
  //           pending: 0,
  //           inProgress: 0,
  //           approved: 0,
  //           rejected: 0,
  //           requireMoreDocuments: 0,
  //         },
  //       });

  //       console.log("LoanStatistics updated successfully.");
  //     } else {
  //       // If the document does not exist, create a new one with the totalLoanAmount set to the current loanAmount
  //       await setDoc(loanStatsRef, {
  //         totalApplications: 1,
  //         statusCounts: {
  //           totalApplications: 1,
  //           approvedLoans: 0,
  //           totalLoanAmount: searchCriteria.financialAmount,
  //           pending: 0,
  //           inProgress: 0,
  //           approved: 0,
  //           rejected: 0,
  //           requireMoreDocuments: 0,

  //         },
  //         bankStatistics: {
  //           [bankName]: {
  //             totalApplications: 1,
  //             approvedLoans: 0,
  //             totalLoanAmount: searchCriteria.financialAmount,
  //             pending: 0,
  //             inProgress: 0,
  //             approved: 0,
  //             rejected: 0,
  //             requireMoreDocuments: 0,
  //           },
  //         },
  //       });

  //       console.log("LoanStatistics document created successfully.");
  //     }
  //   } catch (error) {
  //     console.error("Error submitting loan application:", error);
  //     alert("There was an error submitting your application.");
  //   }
  // };

  const handleSubmitLoanDocuments = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    if (
      !documents.bank_statement ||
      !documents.dbr_ratio ||
      !documents.salary_definition
    ) {
      alert("Please upload all the required documents.");
      setLoading(false); // Stop loading on error
      return;
    }

    if (!loanId) {
      alert("Loan ID is missing. Please apply for a loan first.");
      setLoading(false); // Stop loading on error
      return;
    }

    try {
      const storage = getStorage();
      const folderPath = "bank_documents";
      const documentURLs = {};

      // Step 1: Upload each document to Firebase Storage and get the download URLs
      const uploadTasks = Object.entries(documents).map(async ([key, file]) => {
        const fileRef = ref(storage, `${folderPath}/${loanId}_${key}`);
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        documentURLs[key] = {
          status: "submitted", // Set the appropriate status for each document
          url,
        };
      });

      // Wait for all upload tasks to complete
      await Promise.all(uploadTasks);

      // Step 2: Submit Loan Documents to Firestore
      const loanDocumentData = {
        loanId,
        referralId: selectedReferral?.id || "", // Use empty string if not selected
        referralName: selectedReferral?.companyName || "", // Use empty string if not selected
        referralCommision: selectedReferral?.commission || "",
        documents: documentURLs,
        timestamp: new Date().toISOString(),
      };

      const loanDocumentsRef = collection(db, "LoanDocuments");
      await addDoc(loanDocumentsRef, loanDocumentData);

      // Step 3: Update loan history
      const loanHistoryRef = doc(db, "LoanHistories", loanId);
      const historyEntry = {
        timestamp: new Date().toISOString(),
        document_action: "Document Submitted",
        status: "pending",
        comments: "All required documents submitted for review.",
      };

      await setDoc(
        loanHistoryRef,
        {
          loanId,
          loanHistory: arrayUnion(historyEntry),
        },
        { merge: true }
      );

      toast.success("Your documents have been submitted successfully.");
      logUserActivity("User Added Loan Documents");
      setShowModal(false);
      console.log("Loan documents submitted and history created/updated.");
    } catch (error) {
      console.error(
        "Error submitting loan documents or updating history:",
        error
      );
      alert("There was an error submitting your documents.");
    } finally {
      setLoading(false); // Stop loading after operation (success or failure)
    }
  };

  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const handleCheckboxChange = (program) => {
    setSelectedPrograms((prevSelected) => {
      if (prevSelected.includes(program)) {
        return prevSelected.filter((p) => p !== program);
      } else {
        return [...prevSelected, program];
      }
    });
  };

  const handleApplySelected = () => {
    console.log("Applying for programs:", selectedPrograms);

    // Create loanApplicationData as needed, assuming it exists in the context
    const loanApplicationData = {
      userId: selectedClient.id,
      userName: selectedClient.name,
      nationalID: selectedClient.nationalID,
      contact: selectedClient.contact,
      email: selectedClient.email,
      employmentStatus: selectedClient.employmentStatus,
      employeeAge: selectedClient.age,
      loanProgram: selectedPrograms[0]?.programName, // Assuming it's the first program
      bank: selectedPrograms[0]?.bank || "Unknown Bank",
      eligibilityStatus: "In Progress",
      approvalStatus: "Submitted",
      approvalDate: new Date().toISOString(),
      loanAmount: searchCriteria.financialAmount,
      // creditLimit: selectedPrograms[0]?.loanDetails.maxLoanAmount,
      // interestRate:
      //   selectedPrograms[0]?.baseFields.loanDetails.interestRate || 5.5,
      loanId: `CBF-AP-${(Math.random() * 10000).toFixed(0)}`, // Generate loan ID dynamically
    };

    navigate("/single-f-programs", {
      state: {
        programs: selectedPrograms,
        loanApplicationData: loanApplicationData,
      },
    });
  };

  const programOptions = programTypes.map((program) => ({
    value: program.id,
    label: program.programtype,
  }));

  const clientOptions = clients.map((client) => ({
    value: client.id,
    label: client.name,
  }));

  return (
    <div className="p-4">
      <h2 className="font-bold text-xl text-secondaryColor mb-6">Search Finance Program</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Select Client */}

        <div className="flex justify-between gap-6">
          <div className="w-full">
            <Controller
              name="client"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={clientOptions}
                  selectedValue={selectedClient}
                  setSelectedValue={handleClientChange}
                  placeholder="Select a client"
                  {...field}
                />
              )}
            />
          </div>

          <div className="w-full">
            <Controller
              name="programtype"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={programOptions}
                  selectedValue={searchCriteria.programType}
                  setSelectedValue={(value) => {
                    setSelectedProgramType(value);
                    field.onChange(value); // Update form control
                    setSearchCriteria((prev) => ({
                      // Update search criteria
                      ...prev,
                      programType: value,
                    }));
                  }}
                  placeholder="Select a programType"
                />
              )}
            />
          </div>
        </div>

        <div className="flex justify-between gap-6">
          {/* Basic Salary Input */}
          <div className="w-full">
            <label
              htmlFor="basicSalary"
              className="block text-sm font-medium text-gray-700"
            >
              Basic Salary
            </label>
            <input
              type="number"
              id="basicSalary"
              name="basicSalary"
              value={searchCriteria.basicSalary}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              min="0"
              readOnly // Prevent direct edits
            />
          </div>

          {/* Financial Amount Input */}
          <div className="w-full">
            <label
              htmlFor="financialAmount"
              className="block text-sm font-medium text-gray-700"
            >
              Financial Amount
            </label>
            <input
              type="number"
              id="financialAmount"
              name="financialAmount"
              value={searchCriteria.financialAmount}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              min="0"
            />
          </div>
        </div>

        <button
          type="submit"
          className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-4 "
        >
          Search Loan Programs
        </button>
      </form>

      {/* Display Error */}
      {error && <p className="text-red-500 mt-4">{error}</p>}

      <div className="mt-6">
        <h3 className="text-lg font-semibold">Available Loan Programs</h3>
        {loanPrograms.length > 0 ? (
          <ul>
            {loanPrograms.map((program, index) => {
              // Age validation
              // const ageMatches =
              //   selectedClient &&
              //   selectedClient.age >= program.baseFields.eligibilityCriteria.minAge &&
              //   selectedClient.age <= program.baseFields.eligibilityCriteria.maxAge;

              return (
                <li key={index} className="mb-2 shadow-custom p-4 rounded">
                  {/* Program Name */}
                  <div className="font-semibold">
                    {program.loan_program_name}
                  </div>

                  {/* Financial Details Section */}
                  <div className="flex justify-between">
                    <div>
                      Min Salary:{" "}
                      {program.financial_details.financing_limits
                        ?.minimum_financing || "N/A"}
                    </div>
                  
                    <div>
                      Max Loan Amount:{" "}
                      {program.financial_details.financing_limits
                        ?.maximum_financing || "N/A"}
                    </div>
                  </div>

                  <div className="flex justify-between my-4">
                    <div>
                      Starting Date:{" "}
                      {program.loan_term_details?.starting_date || "N/A"}
                    </div>
                    <div>
                      Ending Date:{" "}
                      {program.loan_term_details?.ending_date || "N/A"}
                    </div>
                  </div>

                  {/* Program Specific Details */}
                  <div className="mt-4">
                    <div className="font-semibold mb-2">
                      Program Specific Details:
                    </div>

                    {/* Iterate over all keys in program_specific_details */}
                    {program.program_specific_details &&
                      Object.entries(program.program_specific_details).map(
                        ([key, value], detailIndex) => (
                          <div key={detailIndex} className="mb-2">
                            <div className="">{key}</div>
                          </div>
                        )
                      )}
                  </div>
                  {/* Checkbox for Selection */}
                  <div className="mt-2">
                    <input
                      type="checkbox"
                      id={`select-${index}`}
                      className="mr-2"
                      onChange={() => handleCheckboxChange(program)}
                    />
                    <label htmlFor={`select-${index}`} className="text-sm">
                      Select this program
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No loan programs match your criteria.</p>
        )}

        {/* Centralized Apply Button */}
        {selectedPrograms.length > 0 && (
          <div className="mt-4 flex justify-center">
            <button
              onClick={handleApplySelected}
              className="bg-gradient-to-r from-secondaryColor to-primaryColor  text-white py-2 px-4 rounded hover:bg-primaryColorDark"
            >
              Apply for Selected Programs
            </button>
          </div>
        )}
      </div>

      {/* Modal for Document Upload */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h3 className="text-lg font-semibold mb-4">
              Upload Loan Documents
            </h3>
            <div className="w-full mb-4">
              <label className="block text-sm font-medium text-gray-700">
                <select
                  value={selectedReferral ? selectedReferral.id : ""}
                  onChange={handleReferralChange}
                  className="border border-gray-300 w-full rounded-lg p-2"
                >
                  <option value="">Select a Broker Company</option>
                  {referral.map((refral) => (
                    <option key={refral.id} value={refral.id}>
                      {refral.companyName}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <label className="capitalize">Bank Statement</label>
            <input
              type="file"
              name="bank_statement"
              onChange={handleDocumentChange}
              className="file:bg-primaryColor file:px-6 file:rounded file:border-none file:text-white
        file:shadow-lg file:shadow-lightGray file:m-2 w-full rounded my-2
        bg-lightGray
        file:py-1"
            />

            <label className="capitalize">DBR Ratio</label>
            <input
              type="file"
              name="dbr_ratio"
              onChange={handleDocumentChange}
              className="file:bg-primaryColor file:px-6 file:rounded file:border-none file:text-white
        file:shadow-lg file:shadow-lightGray file:m-2 w-full rounded my-2
        bg-lightGray
        file:py-1"
            />

            <label className="capitalize">Salary Definition</label>
            <input
              type="file"
              name="salary_definition"
              onChange={handleDocumentChange}
              className="file:bg-primaryColor file:px-6 file:rounded file:border-none file:text-white
        file:shadow-lg file:shadow-lightGray file:m-2 w-full rounded my-2
        bg-lightGray
        file:py-1"
            />

            <div className="flex justify-between mt-4">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-500 text-white rounded-lg py-2 px-6"
              >
                Close
              </button>
              <button
                onClick={handleSubmitLoanDocuments}
                className="bg-gradient-to-r from-secondaryColor to-primaryColor text-white rounded-lg py-2 px-6"
              >
                {loading ? "Loading..." : " Submit Documents"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FProgram;
