import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import SelectField from "../../components/fields/SelectField";
import { useNavigate } from "react-router-dom";
import { logUserActivity } from "../../services/logUserActivity";

// Zod Schema for validation
const referralCompanySchema = z.object({
  companyName: z
    .string()
    .min(1, { message: "Company Name is required" })
    .max(50, { message: "Company Name cannot exceed 50 characters" }),
  contactDetail: z
    .string()
    .min(1, { message: "Contact Detail is required" })
    .max(200, { message: "Contact Detail cannot exceed 200 characters" }),
  contactPersonName: z
    .string()
    .min(1, { message: "Contact Person Name is required" })
    .max(50, { message: "Contact Person Name cannot exceed 50 characters" }),
  contactEmail: z
    .string()
    .email({ message: "Invalid email address" })
    .optional(),
  type: z.enum(["individual", "corporate"], {
    message: "Type must be either Individual or Corporate",
  }),
});

const AddReferralCompany = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(referralCompanySchema),
    defaultValues: {
      companyName: "",
      contactDetail: "",
      contactPersonName: "",
      contactEmail: "",
      type: "individual", // Default type is Individual
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      // Fetch existing broker companies to find the highest serial number
      const brokerCompanyCollectionRef = collection(db, "BrokerCompany");
      const brokerCompanySnapshot = await getDocs(brokerCompanyCollectionRef);
      const brokerCompaniesData = brokerCompanySnapshot.docs.map((doc) => doc.data());
  
      // Find the highest serial number from existing brokerIds
      const highestBrokerId = brokerCompaniesData.reduce((max, company) => {
        // Check if the brokerId exists and has the expected format
        if (company.brokerId && company.brokerId.includes('-')) {
          const brokerSerialNo = parseInt(company.brokerId.split('-')[1], 10);
          return brokerSerialNo > max ? brokerSerialNo : max;
        }
        return max; // Skip malformed brokerId or undefined
      }, 0);
  
      // Generate the next serial number for the new broker company
      const newBrokerId = `BrokerComp-${(highestBrokerId + 1).toString().padStart(4, '0')}`;
  
      // Add the new broker company data with the generated brokerId
      const newCompanyData = { ...data, brokerId: newBrokerId };
  
      // Add data to Firebase
      await addDoc(brokerCompanyCollectionRef, newCompanyData);
      toast.success("Referral company added successfully!");
      logUserActivity("User Added Broker Company");
      navigate("/broker-company");
    } catch (error) {
      console.error("Error adding referral company:", error);
      toast.error("Failed to add referral company.");
    }
  };
  
  

  return (
    <>
      <div>
        <h1 className="font-bold text-xl text-secondaryColor mb-6">إنشاء شركة وسيط</h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)} >
        <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
          <div className="flex justify-between gap-6">
            <div className="w-full">
              <InputField
                label="اسم الشركة"
                name="companyName"
                register={register}
                error={errors.companyName?.message}
                  placeholder="أدخل اسم الشركة"
              />
            </div>

            <div className="w-full">
              <InputField
                label="تفاصيل الاتصال"
                name="contactDetail"
                register={register}
                error={errors.contactDetail?.message}
                 placeholder="أدخل تفاصيل الاتصال"
              />
            </div>
          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              <InputField
                label="اسم جهة الاتصال"
                name="contactPersonName"
                register={register}
                error={errors.contactPersonName?.message}
                 placeholder="أدخل اسم جهة الاتصال"
              />
            </div>
            <div className="w-full">
              <InputField
                label="البريد الإلكتروني للاتصال"
                name="contactEmail"
                type="email"
                register={register}
                error={errors.contactEmail?.message}
                 placeholder="أدخل البريد الإلكتروني لجهة الاتصال"
              />
            </div>
          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              <SelectField
                label="يكتب"
                name="type"
                options={[
                  { id: "individual", name: "Individual" },
                  { id: "corporate", name: "Corporate" },
                ]}
                register={register}
                error={errors.type?.message}
              />
            </div>
          </div>

          <button
            type="submit"
            className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 mt-6"
          >
            إرسال شركة وسيط
          </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddReferralCompany;
