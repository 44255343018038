import React from "react";

const SelectField = ({ label, options, register, name, error, onChange, disabled }) => {
  return (
    <div className="">
      <label>{label}</label>
      <select
        {...register(name)}
        onChange={onChange}
        className="rounded-md w-full px-2 cursor-pointer py-2 shadow border-[1px] border-neutral-300"
        disabled={disabled}
      >
        <option value="" >يختار {label}</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}  >
            {option.name || option.departmentName}
          </option>
        ))}
      </select>
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default SelectField;
