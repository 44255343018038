// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyB4bISmPzi8aqk5Rf7pN2yrgASQ8LwwSqg",
  authDomain: "cbf-project-3f3d9.firebaseapp.com",
  projectId: "cbf-project-3f3d9",
  storageBucket: "cbf-project-3f3d9.firebasestorage.app",
  messagingSenderId: "724988419323",
  appId: "1:724988419323:web:0c3acf42cddeeef247e45e",
  measurementId: "G-5QER2NX191"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);