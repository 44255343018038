import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import InputField from "../../components/fields/TextInput";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

const dataSchema = z.object({
  programtype: z
    .string()
    .min(1, { message: "Program type is required" })
    .max(30, { message: "Program type cannot exceed 30 characters" }),
});

const UpdateProgramType = () => {
  const { id } = useParams(); // Get the program type ID from the URL
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(dataSchema),
  });

  // Fetch the program type details
  useEffect(() => {
    const fetchProgramType = async () => {
      try {
        const docRef = doc(db, "ProgramType", id);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          setValue("programtype", docSnapshot.data().programtype);
        } else {
          toast.error("Program type not found");
          navigate("/program-type");
        }
      } catch (error) {
        console.error("Error fetching program type:", error);
        toast.error("Failed to fetch program type");
      }
    };

    fetchProgramType();
  }, [id, setValue, navigate]);

  // Handle form submission
  const onSubmit = async (data) => {
    try {
      const docRef = doc(db, "ProgramType", id);
      await updateDoc(docRef, data);

      toast.success("Program type updated successfully");
      navigate("/program-type");
    } catch (error) {
      console.error("Error updating program type:", error);
      toast.error("Failed to update program type");
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl text-secondaryColor mb-6">تحديث نوع البرنامج</h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <InputField
              label="نوع البرنامج"
              error={errors.programtype?.message}
              register={register}
              name="programtype"
              placeholder="أدخل نوع البرنامج"
            />

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
             تحديث نوع البرنامج
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateProgramType;
