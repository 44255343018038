import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { Controller, useForm } from "react-hook-form";
import SearchableDropdown from "../../components/fields/SearchableDropdown";
import { useParams, useNavigate } from "react-router-dom";

const UpdateCategory = () => {
  const { control, setValue, handleSubmit, reset } = useForm();
  const [programTypes, setProgramTypes] = useState([]);
  const [selectedProgramType, setSelectedProgramType] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const { id } = useParams(); // Get category ID from the URL
  const navigate = useNavigate();

  // Fetch program types from Firestore
  useEffect(() => {
    const fetchProgramTypes = async () => {
      try {
        const programTypesCollectionRef = collection(db, "ProgramType");
        const programTypeSnapshot = await getDocs(programTypesCollectionRef);
        const programTypesData = programTypeSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProgramTypes(programTypesData);
      } catch (error) {
        console.error("Error fetching program types:", error);
        toast.error("Failed to fetch program types");
      }
    };

    fetchProgramTypes();
  }, []);

  // Fetch existing category details
  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const categoryDocRef = doc(db, "Categories", id);
        const categorySnapshot = await getDoc(categoryDocRef);

        if (categorySnapshot.exists()) {
          const categoryData = categorySnapshot.data();
          setSelectedProgramType(categoryData.programTypeId);
          setCategoryName(categoryData.categoryName);

          setValue("programtype", categoryData.programTypeId);
        } else {
          toast.error("Category not found");
          navigate("/view-categories");
        }
      } catch (error) {
        console.error("Error fetching category details:", error);
        toast.error("Failed to fetch category details");
      }
    };

    fetchCategoryDetails();
  }, [id, setValue, navigate]);

  // Map program types to dropdown options
  const programOptions = programTypes.map((program) => ({
    value: program.id,
    label: program.programtype,
  }));

  // Handle form submission
  const onSubmit = async () => {
    if (!selectedProgramType || !categoryName) {
      toast.error("Please select a program type and enter a category name");
      return;
    }

    // Find the program name based on the selected program type
    const selectedProgram = programTypes.find(
      (program) => program.id === selectedProgramType
    );
    const programName = selectedProgram ? selectedProgram.programtype : "";

    try {
      const categoryDocRef = doc(db, "Categories", id);
      await updateDoc(categoryDocRef, {
        programTypeId: selectedProgramType,
        categoryName,
        programName,
      });

      toast.success("Category updated successfully");
      navigate("/view-categories");
      reset();
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error("Failed to update category");
    }
  };

  return (
    <>
      <div>
        <h1  className="font-bold text-xl text-secondaryColor mb-6">تحديث الفئة</h1>
      </div>

      <div className="rounded-lg shadow">
        <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
          {/* Program Type Dropdown */}
          <div className="mb-4">
            <Controller
              name="programtype"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={programOptions}
                  selectedValue={selectedProgramType}
                  setSelectedValue={(value) => {
                    setSelectedProgramType(value);
                    field.onChange(value); // Sync with react-hook-form
                  }}
                  placeholder="حدد نوع البرنامج"
                />
              )}
            />
          </div>

          {/* Input for Category Name */}
          <div className="mb-4">
            <input
              type="text"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="أدخل اسم الفئة"
            />
          </div>

          {/* Submit Button */}
          <button
            onClick={handleSubmit(onSubmit)}
            className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
          >
          تحديث الفئة
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdateCategory;
