import React from 'react';
import { NavLink } from 'react-router-dom';


const SidebarNavLink = ({ to, icon: Icon, label, isCollapsed, id }) => {
  return (
    <NavLink
      to={to}
      id={id}
      className={({ isActive }) =>
        `flex items-center rounded-lg text-sm outline-none  ${
          isCollapsed ? 'justify-center px-2 py-3' : 'px-4 py-3'
        } ${
          isActive ? 'text-white bg-gradient-to-r from-secondaryColor to-primaryColor' : 'hover:bg-gradient-to-r from-secondaryColor to-primaryColor hover:text-white'
        }`
      }
    >
      {({ isActive }) => (
        <>
          <Icon
            size={20}
            className={`text-black ${isCollapsed ? 'text-xl' : 'ml-3 text-xl'} ${
              isActive ? 'text-white' : ''
            }`}
          />
          {!isCollapsed && <span className="whitespace-nowrap  ">{label}</span>}
        </>
      )}
    </NavLink>
  );
};

export default SidebarNavLink;
