import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";

function Layout() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  

  const handleCollapseToggle = (collapsedState) => {
    setIsCollapsed(collapsedState);
  };

  return (
    <>
      <Navbar />
      <div className="flex h-screen">
       
          <>
          <div className="font-sans dark:bg-darkthemePrimaryColor dark:text-white">
              <Sidebar
                onToggleCollapse={handleCollapseToggle}
                isCollapsed={isCollapsed}
                
              />
            </div>
            <div
              className={`flex-1  transition-all duration-500 ${
                isCollapsed ? "ml-[80px]" : "ml-[150px]"
              } p-4`}
            >
              <Outlet />
            </div>
           
          </>
       
      </div>
    </>
  );
}

export default Layout;
