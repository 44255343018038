import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserAuth } from "../../context/UserAuthContext"; 
import { db } from "../../firebase";
import { doc, setDoc, serverTimestamp, collection, getDocs } from "firebase/firestore";

function SignUp() {
  const [fullname, setFullname] = useState(""); // State for fullname
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const { signUp } = useUserAuth() || {};
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesCollection = collection(db, "roles");
        const rolesSnapshot = await getDocs(rolesCollection);
        const rolesList = rolesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRoles(rolesList);
      } catch (error) {
        console.error("Error fetching roles: ", error);
      }
    };

    fetchRoles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      const userCredential = await signUp(email, password);
      const user = userCredential.user;
  
      if (user) {
        const userData = {
          uid: user.uid,
          fullname, // Store fullname
          email: user.email,
          roleId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };
  
        // Fetch the role name from the roles array
        const role = roles.find((role) => role.id === roleId);
  
        if (role) {
          // Add roleName to the userData object
          userData.roleName = role.role; 
  
          // Store user data along with roleName in localStorage
          localStorage.setItem("user", JSON.stringify(userData));
        }
  
        await setDoc(doc(db, "Users", user.uid), userData);
      }
      toast.success("User Registered Successfully!", {
        position: "top-center",
      });
      navigate("/signin");
    } catch (err) {
      setError(err.message);
      toast.error(err.message, { position: "top-center" });
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-secondaryColor to-primaryColor">
      <div className="bg-white shadow-lg rounded-lg p-8 w-96">
        <h2 className="text-center text-2xl font-bold text-gray-800 mb-4">
        إنشاء حساب
        </h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            placeholder="الاسم الكامل"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            className="w-full px-4 py-2 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
            required
          />
          <input
            type="email"
            placeholder="البريد الإلكتروني أو رقم الهاتف"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
            required
          />
          <input
            type="password"
            placeholder="كلمة المرور"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
            required
          />
          <select
            value={roleId}
            onChange={(e) => setRoleId(e.target.value)}
            className="w-full px-4 py-2 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
            required
          >
            <option value="">حدد دورك</option>
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.role}
              </option>
            ))}
          </select>
          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 mt-6 text-white rounded-lg bg-gradient-to-r from-secondaryColor to-primaryColor"
          >
            {loading ? "التسجيل..." : "اشتراك"}
          </button>
        </form>
        <p className="text-center my-4">
        لديك حساب بالفعل?{" "}
          <Link to="/signin" className="text-blue-800">
          تسجيل الدخول
          </Link>
        </p>
      </div>
    </div>
  );
}

export default SignUp;
