import React, { useEffect, useState } from "react";
import { collection, addDoc, getDocs,query, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import SearchableDropdown from "../../components/fields/SearchableDropdown";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";

const AddLoanProgram = () => {
  const [formData, setFormData] = useState({});
  const [programTypes, setProgramType] = useState([]);
  const [selectedProgramType, setSelectedProgramType] = useState("");
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");


  const [requiredDocs, setRequiredDocs] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [newDocument, setNewDocument] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch documents from Firestore
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setLoading(true);
        const documentsRef = collection(db, "RequiredDocuments");
        const documentsSnapshot = await getDocs(query(documentsRef));
        
        const documents = documentsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setRequiredDocs(documents);
      } catch (error) {
        console.error("Error fetching documents:", error);
        toast.error("Failed to load documents");
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  const handleDocChange = (docId) => {
    setSelectedDocs(prev => {
      if (prev.includes(docId)) {
        return prev.filter(id => id !== docId);
      } else {
        return [...prev, docId];
      }
    });
  };

  const handleAddNewDocument = async () => {
    if (newDocument.trim()) {
      try {
        setLoading(true);
        const documentsRef = collection(db, "RequiredDocuments");
        
        // Create new document object
        const newDoc = {
          name: newDocument.trim(),
          createdAt: new Date().toISOString(),
          isActive: true
        };

        // Add to Firestore
        const docRef = await addDoc(documentsRef, newDoc);
        
        // Update local state
        setRequiredDocs(prev => [...prev, {
          id: docRef.id,
          ...newDoc
        }]);

        setNewDocument("");
        setShowPopup(false);
        toast.success("Document added successfully");
      } catch (error) {
        console.error("Error adding document:", error);
        toast.error("Failed to add document");
      } finally {
        setLoading(false);
      }
    }
  };

  const navigate = useNavigate();

  const { control, setValue } = useForm();

  const renameKeysToRemoveQuestionMark = (obj) => {
    const cleanObj = {};

    Object.keys(obj).forEach((key) => {
      const newKey = key.replace("?", "");
      if (typeof obj[key] === "object" && obj[key] !== null) {
        cleanObj[newKey] = Array.isArray(obj[key])
          ? obj[key].map((item) =>
              typeof item === "object"
                ? renameKeysToRemoveQuestionMark(item)
                : item
            )
          : renameKeysToRemoveQuestionMark(obj[key]);
      } else {
        cleanObj[newKey] = obj[key];
      }
    });

    return cleanObj;
  };

  const handleChange = (path, value) => {
    setFormData((prevState) => {
      const keys = path.split(".");
      const newState = { ...prevState };
      let current = newState;

      // Traverse the path except the last key
      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        current[key] = current[key] || {};
        current = current[key];
      }

      // Set the value at the final key
      current[keys[keys.length - 1]] = value;

      return newState;
    });
  };

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const banksCollectionRef = collection(db, "Banks");
        const bankSnapshot = await getDocs(banksCollectionRef);
        const banksData = bankSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBanks(banksData);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };

    fetchBanks();
  }, []);

  useEffect(() => {
    setValue("baseFields.selectedBank", selectedBank);
  }, [selectedBank, setValue]);

  const bankOptions = banks.map((bank) => ({
    value: bank.id,
    label: bank.name,
  }));

  useEffect(() => {
    const fetchProgramType = async () => {
      try {
        const programTypesCollectionRef = collection(db, "ProgramType");
        const programTypeSnapshot = await getDocs(programTypesCollectionRef);
        const programTypesData = programTypeSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProgramType(programTypesData);
      } catch (error) {
        console.error("Error fetching program types:", error);
      }
    };

    fetchProgramType();
  }, []);

  useEffect(() => {
    setValue("baseFields.selectedProgramType", selectedProgramType); // Set program type in form state
  }, [selectedProgramType, setValue]);

  const programOptions = programTypes.map((program) => ({
    value: program.id,
    label: program.programtype,
  }));

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesCollectionRef = collection(db, "Categories");
        const categorySnapshot = await getDocs(categoriesCollectionRef);
        const categoriesData = categorySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);



  useEffect(() => {
    if (selectedProgramType) {
      console.log("Selected Program Type:", selectedProgramType);

      const filtered = categories.filter((category) => {
        console.log("Category ProgramTypeId:", category.programTypeId); // Log the category programTypeId to check the value
        return category.programTypeId === selectedProgramType; // Adjust this based on your actual field
      });

      console.log("Filtered Categories:", filtered);
      setFilteredCategories(filtered);
    }
  }, [selectedProgramType, categories]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Find the selected bank object
      const selectedBankObj = banks.find((bank) => bank.id === selectedBank);
  
      // Create a cleaned copy of the form data
      const cleanedFormData = {
        loan_program_name: formData.loan_program_name,
        program_category: formData.program_category,
        financial_details: {
          financing_limits: {
            minimum_financing:
              formData?.financial_details?.financing_limits?.minimum_financing,
            maximum_financing:
              formData?.financial_details?.financing_limits?.maximum_financing,
            financing_percentage:
              formData?.financial_details?.financing_limits?.financing_percentage,
          },
          interest_details: {
            murabaha_ratio:
              formData?.financial_details?.interest_details?.murabaha_ratio,
            fixed_rate: formData?.financial_details?.interest_details?.fixed_rate,
            variable_rate:
              formData?.financial_details?.interest_details?.variable_rate,
          },
          fees_and_charges: {
            application_fee:
              formData?.financial_details?.fees_and_charges?.application_fee,
            processing_fee:
              formData?.financial_details?.fees_and_charges?.processing_fee,
            early_settlement_fee:
              formData?.financial_details?.fees_and_charges?.early_settlement_fee,
          },
        },
        loan_term_details: {
          minimum_years: formData?.loan_term_details?.minimum_years,
          maximum_years: formData?.loan_term_details?.maximum_years,
          starting_date: formData?.loan_term_details?.starting_date,
          ending_date: formData?.loan_term_details?.ending_date,
          repayment_options:
            formData?.loan_term_details?.repayment_options || [],
        },
        required_documents: selectedDocs,
        bank: {
          bankId: selectedBank, // Include bankId
          bankName: selectedBankObj?.name || "", // Include bankName
        },
        program_type: selectedProgramType,
        program_specific_details: Object.entries(
          formData.program_specific_details || {}
        ).reduce((acc, [objectId, fields]) => {
          const objectDef = objects.find((obj) => obj.id === objectId);
          if (!objectDef) return acc;
  
          const objectName = objectDef.objectName;
  
          if (objectDef.isRequired) {
            // If `isRequired` is true, send the complete `objectDef`
            acc[objectName] = { ...objectDef };
          } else {
            // Otherwise, process fields individually
            acc[objectName] = {};
            objectDef.fields.forEach((field) => {
              const fieldValue = fields[field.key];
              acc[objectName][field.key] = fieldValue || "";
            });
          }
  
          return acc;
        }, {}),
      };
  
      // Remove undefined or null values
      const cleanedData = JSON.parse(JSON.stringify(cleanedFormData));
  
      // Store in Firebase
      const docRef = await addDoc(collection(db, "loanPrograms"), cleanedData);
      console.log("Document written with ID: ", docRef.id);
  
      // Clear form after successful submission
      setFormData({});
      setSelectedBank("");
      setSelectedProgramType("");
      setSelectedObjectIds([]);
  
      // Show success message
      toast.success("Loan program successfully created!");
      navigate("/view-loan");
    } catch (error) {
      console.error("Error submitting form: ", error);
      alert("Error creating loan program. Please try again.");
    }
  };
  
  
  

  const [objects, setObjects] = useState([]);
  const [selectedObjectIds, setSelectedObjectIds] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch objects from Firestore
  useEffect(() => {
    const fetchObjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "CustomObjects"));
        const fetchedObjects = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setObjects(fetchedObjects);
      } catch (error) {
        console.error("Error fetching objects:", error);
      }
    };

    fetchObjects();
  }, []);

  console.log("kjkjkjkj",objects)

  const handleDeleteDocument = async (docId) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, "RequiredDocuments", docId));
        // Update the local state to remove the deleted document
        setRequiredDocs(prevDocs => prevDocs.filter(doc => doc.id !== docId));
        toast.success('Document deleted successfully');
      } catch (error) {
        console.error('Error deleting document:', error);
        toast.error('Error deleting document');
      } finally {
        setLoading(false);
      }
    }
  };

const handleObjectSelection = () => {
  const selectedData = objects
    .filter((object) => selectedObjectIds.includes(object.id))
    .reduce((acc, object) => {
      if (object.isRequired) {
        // Send the complete object as it is
        acc[object.id] = formData.program_specific_details?.[object.id] || {};
      } else {
        // Initialize fields properly for non-required objects
        acc[object.id] = object.fields.reduce((fieldAcc, field) => {
          if (field.type === "checkbox") {
            fieldAcc[field.key] = []; // Initialize checkbox fields as empty arrays
          } else {
            fieldAcc[field.key] = ""; // Initialize other fields as empty strings
          }
          return fieldAcc;
        }, {});
      }
      return acc;
    }, {});

  setFormData((prevData) => ({
    ...prevData,
    program_specific_details: {
      ...prevData.program_specific_details,
      ...selectedData,
    },
  }));

  setIsModalOpen(false);
};


  const handleObjectSelectionToggle = (objectId) => {
    setSelectedObjectIds((prevSelected) =>
      prevSelected.includes(objectId)
        ? prevSelected.filter((id) => id !== objectId)
        : [...prevSelected, objectId]
    );
  };

  const handleFieldCheckboxChange = (objectId, fieldKey, value) => {
    setFormData((prevFormData) => {
      // Create a copy of the previous form data
      const newFormData = {
        ...prevFormData,
        program_specific_details: {
          ...prevFormData.program_specific_details,
          [objectId]: {
            ...(prevFormData.program_specific_details[objectId] || {}),
          },
        },
      };

      // Get current values array or initialize it if it doesn't exist
      const currentValues =
        newFormData.program_specific_details[objectId][fieldKey] || [];

      // Toggle the value - if it exists, remove it; if it doesn't, add it
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];

      // Update the form data with the new array of values
      newFormData.program_specific_details[objectId][fieldKey] = updatedValues;

      console.log("Updated checkbox values:", updatedValues); // For debugging
      return newFormData;
    });
  };

  const handleFieldChange = (objectId, fieldKey, value) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };

      // Ensure the program_specific_details object exists for this objectId
      if (!newFormData.program_specific_details[objectId]) {
        newFormData.program_specific_details[objectId] = {};
      }

      // Update the field value
      newFormData.program_specific_details[objectId][fieldKey] = value;

      return newFormData;
    });
  };

  // Handle radio button selection change
  const handleRadioChange = (objectId, fieldKey, value) => {
    setFormData((prevFormData) => {
      const updatedProgramSpecificDetails = {
        ...prevFormData.program_specific_details,
      };

      // Update selected radio value for the object
      if (!updatedProgramSpecificDetails[objectId]) {
        updatedProgramSpecificDetails[objectId] = {};
      }
      updatedProgramSpecificDetails[objectId][fieldKey] = value;

      return {
        ...prevFormData,
        program_specific_details: updatedProgramSpecificDetails,
      };
    });
  };

  const handleRequiredFieldUpdate = (objectId, objectDef) => {
    setFormData((prevFormData) => {
      const updatedDetails = {
        ...prevFormData.program_specific_details,
        [objectId]: objectDef,
      };
      return {
        ...prevFormData,
        program_specific_details: updatedDetails,
      };
    });
  };
  




  return (
    <form onSubmit={handleSubmit}>
      <div className="shadow-lg p-10 rounded-2xl">
        <h2 className="text-2xl">معلومات عامة</h2>

        <div className="flex justify-between gap-6 my-6">
          <div className="w-full">
            <input
              type="text"
              placeholder="اسم البرنامج"
              value={formData.loan_program_name}
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange("loan_program_name", e.target.value)
              }
            />
          </div>

          <div className="w-full">
            <Controller
              name="bank"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={bankOptions}
                  selectedValue={selectedBank}
                  setSelectedValue={(value) => {
                    setSelectedBank(value);
                    field.onChange(value); // Ensure the value is passed to react-hook-form
                  }}
                  placeholder="اختر البنك"
                />
              )}
            />
          </div>
        </div>

        <div className="flex justify-center gap-6 mb-6">
          {/* Program Type Dropdown */}
          <div className="w-full">
            <Controller
              name="programtype"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={programOptions}
                  selectedValue={selectedProgramType}
                  setSelectedValue={(value) => {
                    setSelectedProgramType(value);
                    field.onChange(value); // Ensure the value is passed to react-hook-form
                  }}
                  placeholder="اختر نوع البرنامج"
                />
              )}
            />
          </div>

          {/* Program Category Dropdown (SearchableDropdown) */}
          <div className="w-full">
            <Controller
              name="program_category"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={filteredCategories.map((category) => ({
                    value: category.categoryName, // You can adjust this field if necessary
                    label: category.categoryName, // Adjust according to your category object structure
                  }))}
                  selectedValue={formData?.program_category}
                  setSelectedValue={(value) => {
                    handleChange("program_category", value);
                    field.onChange(value); // Ensure the value is passed to react-hook-form
                  }}
                  placeholder="حدد فئة البرنامج"
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="shadow-lg p-10 rounded-2xl my-10">
        <h2 className="text-2xl pb-6">التفاصيل المالية</h2>
        <h3>حدود التمويل</h3>

        <div className="flex justify-between gap-6 mb-6">
          <div className="w-full">
            <input
              type="number"
              placeholder="الحد الأدنى للتمويل"
              value={
                formData?.financial_details?.financing_limits
                  ?.minimum_financing ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.financing_limits.minimum_financing",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="الحد الأقصى للتمويل"
              value={
                formData?.financial_details?.financing_limits
                  ?.maximum_financing ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.financing_limits.maximum_financing",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="نسبة التمويل"
              value={
                formData?.financial_details?.financing_limits
                  ?.financing_percentage ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.financing_limits.financing_percentage",
                  e.target.value
                )
              }
            />
          </div>
        </div>

        {/* Financial Details Section */}
        <h3>تفاصيل الفائدة</h3>
        <div className="flex justify-between gap-6 mb-6">
          <div className="w-full">
            <input
              type="number"
              placeholder="نسبة المرابحة"
              value={
                formData?.financial_details?.interest_details?.murabaha_ratio ??
                ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.interest_details.murabaha_ratio",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="سعر ثابت"
              value={
                formData?.financial_details?.interest_details?.fixed_rate ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.interest_details.fixed_rate",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="معدل متغير"
              value={
                formData?.financial_details?.interest_details?.variable_rate ??
                ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.interest_details.variable_rate",
                  e.target.value
                )
              }
            />
          </div>
        </div>
        <h3>الرسوم والمصاريف</h3>
        <div className="flex justify-between gap-6">
          <div className="w-full">
            <input
              type="number"
              placeholder="رسوم الطلب"
              value={
                formData?.financial_details?.fees_and_charges
                  ?.application_fee ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.fees_and_charges.application_fee",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="رسوم المعالجة"
              value={
                formData?.financial_details?.fees_and_charges?.processing_fee ??
                ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.fees_and_charges.processing_fee",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="رسوم التسوية المبكرة"
              value={
                formData?.financial_details?.fees_and_charges
                  ?.early_settlement_fee ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.fees_and_charges.early_settlement_fee",
                  e.target.value
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="shadow-lg p-10 rounded-2xl">
        {/* Loan Term Details Section */}
        <h2 className="text-2xl pb-6">تفاصيل مدة القرض</h2>
        <div className="flex justify-between gap-6">
          {/* Minimum Years */}
          <div className="w-full">
            <input
              type="number"
              placeholder="الحد الأدنى للسنوات"
              value={formData?.loan_term_details?.minimum_years ?? ""}
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange("loan_term_details.minimum_years", e.target.value)
              }
            />
          </div>

          {/* Maximum Years */}
          <div className="w-full">
            <input
              type="number"
              placeholder="الحد الأقصى للسنوات"
              value={formData?.loan_term_details?.maximum_years ?? ""}
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange("loan_term_details.maximum_years", e.target.value)
              }
            />
          </div>
        </div>

        <div className="flex justify-between gap-6 mt-6">
          {/* Starting Date */}
          <div className="w-full">
            <label htmlFor="">تاريخ البدء</label>
            <input
              type="date"
              placeholder="تاريخ البدء"
              value={formData?.loan_term_details?.starting_date ?? ""}
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange("loan_term_details.starting_date", e.target.value)
              }
            />
          </div>

          {/* Ending Date */}
          <div className="w-full">
            <label htmlFor="">تاريخ الانتهاء</label>
            <input
              type="date"
              placeholder="تاريخ الانتهاء"
              value={formData?.loan_term_details?.ending_date ?? ""}
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange("loan_term_details.ending_date", e.target.value)
              }
            />
          </div>
        </div>
      </div>

      <div className="shadow-lg p-10 rounded-2xl">
      <div className="flex justify-between items-center pb-6">
        <h2 className="text-2xl">المستندات المطلوبة</h2>
      </div>

      <div className="w-full">
        {requiredDocs.length === 0 && !loading ? (
          <p className="text-gray-500">لا توجد وثائق متاحة</p>
        ) : (
          requiredDocs.map((doc) => (
            <div key={doc.id} className="flex items-center gap-2 mb-3 hover:bg-gray-50 p-2 rounded">
              <input
                type="checkbox"
                id={doc.id}
                value={doc.name}
                checked={selectedDocs.includes(doc.id)}
                onChange={() => handleDocChange(doc.id)}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label 
                htmlFor={doc.id}
                className="flex-1 cursor-pointer text-gray-700"
              >
                {doc.name}
              </label>
              <button
                onClick={() => handleDeleteDocument(doc.id)}
                className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                title="Delete document"
                type="button"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-5 w-5" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                  />
                </svg>
              </button>
            </div>
          ))
        )}

        <button
          type="button"
          onClick={() => setShowPopup(true)}
          className="mt-4 text-blue-600 hover:text-blue-700 underline flex items-center gap-2"
          disabled={loading}
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-5 w-5" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M12 4v16m8-8H4" 
            />
          </svg>
          إضافة مستند جديد
        </button>
      </div>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <h3 className="text-lg font-medium mb-4">إضافة مستند جديد</h3>
            <input
              type="text"
              value={newDocument}
              onChange={(e) => setNewDocument(e.target.value)}
              className="border border-gray-300 p-2 mb-4 w-full rounded focus:ring-blue-500 focus:border-blue-500"
              placeholder="أدخل اسم الوثيقة"
              disabled={loading}
            />
            <div className="flex gap-2 justify-end">
              <button
                onClick={() => setShowPopup(false)}
                className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-50"
                disabled={loading}
              >
                يلغي
              </button>
              <button
                onClick={handleAddNewDocument}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
                disabled={loading || !newDocument.trim()}
              >
                {loading ? "...اضافة" : "إضافة مستند"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>

   {/* Program Specific Details Section */}
{/* Program Specific Details Section */}
<div className="shadow-lg p-10 rounded-2xl">
  <div className="flex justify-between items-center">
    <h2 className="text-3xl">تفاصيل خاصة بالبرنامج</h2>
    <button
      type="button"
      onClick={() => setIsModalOpen(true)}
      className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 py-2"
    >
      إضافة كائن مخصص
    </button>
  </div>

  {formData.program_specific_details &&
    Object.keys(formData.program_specific_details).length > 0 && (
      <div className="mt-6">
        {Object.entries(formData.program_specific_details).map(
          ([objectId, objectFields]) => {
            const objectDef = objects.find((obj) => obj.id === objectId);
            console.log("objectDef",objectDef)
            if (!objectDef) return null;

            return (
              <div key={objectId} className="mb-4">
                <h3 className="text-xl flex items-center">
                  {objectDef.objectName}
                  {objectDef.isRequired && (
                    <span className="text-red-500 text-sm ml-2">(مطلوب)</span>
                  )}
                </h3>

                {/* Map through the object's fields */}
                {objectDef.fields.map((field) => {
                  const fieldValue = objectFields[field.key];
                  const isFieldDisabled = objectDef.isRequired === true;

                  return (
                    <div key={field.id} className="mb-4">
                      <label className="block mb-2 capitalize">
                        {field.key}
                        {isFieldDisabled && <span className="text-red-500 ml-1">*</span>}
                      </label>

                      {/* Radio button rendering */}
                      {field.type === "radio" &&
                        Array.isArray(field.radioValues) && (
                          <div>
                            {field.radioValues.map((value, idx) => (
                              <div
                                key={idx}
                                className="inline-block mr-4"
                              >
                                <input
                                  type="radio"
                                  id={`${objectId}-${field.key}-${value}`}
                                  name={`${objectId}-${field.key}`}
                                  value={value}
                                  checked={fieldValue === value}
                                  onChange={() => {
                                    if (objectDef.isRequired) {
                                      handleRequiredFieldUpdate(objectId, objectDef);
                                    } else {
                                      handleRadioChange(objectId, field.key, value);
                                    }
                                  }}
                                  disabled={isFieldDisabled}
                                  className={`mr-2 ${isFieldDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                />
                                <label
                                  htmlFor={`${objectId}-${field.key}-${value}`}
                                  className={isFieldDisabled ? 'text-gray-500 cursor-not-allowed' : ''}
                                >
                                  {value}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}

                      {/* Checkbox rendering */}
                      {field.type === "checkbox" &&
                        Array.isArray(field.checkboxValues) && (
                          <div>
                            {field.checkboxValues.map((value, idx) => {
                              const currentValues = Array.isArray(fieldValue)
                                ? fieldValue
                                : [];

                              return (
                                <div
                                  key={idx}
                                  className="inline-block mr-4"
                                >
                                  <input
                                    type="checkbox"
                                    id={`${objectId}-${field.key}-${value}`}
                                    value={value}
                                    checked={currentValues.includes(value)}
                                    onChange={() => {
                                      if (objectDef.isRequired) {
                                        handleRequiredFieldUpdate(objectId, objectDef);
                                      } else {
                                        handleFieldCheckboxChange(objectId, field.key, value);
                                      }
                                    }}
                                    disabled={isFieldDisabled}
                                    className={`mr-2 ${isFieldDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                  />
                                  <label
                                    htmlFor={`${objectId}-${field.key}-${value}`}
                                    className={isFieldDisabled ? 'text-gray-500 cursor-not-allowed' : ''}
                                  >
                                    {value}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        )}

                      {/* Text or Number input field */}
                      {(field.type === "text" || field.type === "number") && (
                        <input
                          type={field.type}
                          value={fieldValue || ""}
                          onChange={(e) => {
                            if (objectDef.isRequired) {
                              handleRequiredFieldUpdate(objectId, objectDef);
                            } else {
                              handleFieldChange(objectId, field.key, e.target.value);
                            }
                          }}
                          
                          disabled={isFieldDisabled}
                          className={`w-full p-2 border rounded ${
                            isFieldDisabled ? 'bg-gray-100 cursor-not-allowed border-gray-300' : ''
                          }`}
                        />
                      )}

                      {/* Date input field */}
                      {field.type === "date" && (
                        <input
                          type="date"
                          value={fieldValue || ""}
                          onChange={(e) => {
                            if (objectDef.isRequired) {
                              handleRequiredFieldUpdate(objectId, objectDef);
                            } else {
                              handleFieldChange(objectId, field.key, e.target.value);
                            }
                          }}
                          
                          disabled={isFieldDisabled}
                          className={`w-full p-2 border rounded ${
                            isFieldDisabled ? 'bg-gray-100 cursor-not-allowed border-gray-300' : ''
                          }`}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          }
        )}
      </div>
    )}

  {/* Modal for Adding Custom Objects */}
  <Modal
    isOpen={isModalOpen}
    onRequestClose={() => setIsModalOpen(false)}
    contentLabel="Add Custom Object"
    className="bg-white p-6 shadow-lg rounded-md w-1/2 mx-auto"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
  >
    <h2 className="text-xl mb-4">إضافة كائن مخصص</h2>
    <div className="space-y-4">
      {objects.map((object) => (
        <div key={object.id} className="flex items-center gap-2">
          <input
            type="checkbox"
            id={`object-select-${object.id}`}
            checked={selectedObjectIds.includes(object.id)}
            onChange={() => handleObjectSelectionToggle(object.id)}
          />
          <label htmlFor={`object-select-${object.id}`}>
            {object.objectName}
            {object.isRequired && (
              <span className="text-red-500 text-sm ml-2">(الحقول المطلوبة)</span>
            )}
          </label>
        </div>
      ))}
    </div>
    <div className="mt-4 flex justify-end">
      <button
        onClick={handleObjectSelection}
        className="bg-primaryColor text-white px-4 py-2 rounded-lg"
      >
        يحفظ
      </button>
      <button
        onClick={() => setIsModalOpen(false)}
        className="ml-2 bg-gray-300 px-4 py-2 rounded-lg"
      >
        يلغي
      </button>
    </div>
  </Modal>
</div>

      {/* Add similar sections for financial requirements, financial details, loan terms, and program-specific details */}

      <button
        type="submit"
        className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
      >
        إضافة برنامج القرض
      </button>
    </form>
  );
};

export default AddLoanProgram;
