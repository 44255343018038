import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { FiEdit, FiTrash2 } from "react-icons/fi";

const ViewCustomObjects = () => {
  const [objects, setObjects] = useState([]);
  const [expandedObjectId, setExpandedObjectId] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchObjects = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "CustomObjects"));
        const objectsMap = {};

        querySnapshot.forEach((doc) => {
          objectsMap[doc.id] = {
            id: doc.id,
            ...doc.data(),
          };
        });

        const uniqueObjects = Object.values(objectsMap);

        // Sort by updatedAt in descending order
        uniqueObjects.sort((a, b) => {
          return new Date(b.updatedAt || 0) - new Date(a.updatedAt || 0);
        });

        setObjects(uniqueObjects);
      } catch (error) {
        console.error("Error fetching objects:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchObjects();
  }, []); // Empty dependency array for one-time fetch

  const handleCardToggle = (objectId) => {
    setExpandedObjectId((prevId) => (prevId === objectId ? null : objectId));
  };

  const handleEditClick = (object) => {
    navigate(`/edit-field/${object.id}`, { state: { object } });
  };

  const handleDeleteClick = async (objectId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this object?");
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "CustomObjects", objectId));
        alert("Object deleted successfully.");
        setObjects((prevObjects) => prevObjects.filter((obj) => obj.id !== objectId));
      } catch (error) {
        console.error("Error deleting object:", error);
        alert("Failed to delete the object. Please try again.");
      }
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-bold text-xl text-secondaryColor">View Custom Fields</h1>
        </div>
        <div>
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
            <Link to="/add-fields">Add Custom Field</Link>
          </button>
        </div>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="space-y-4">
          {objects.length > 0 ? (
            objects.map((object) => (
              <div
                key={object.id}
                className={`p-4 border rounded shadow transition ${
                  expandedObjectId === object.id ? "bg-gray-100" : "bg-white"
                }`}
              >
                <div className="flex justify-between items-center">
                  <div
                    className="cursor-pointer"
                    onClick={() => handleCardToggle(object.id)}
                  >
                    <h2 className="text-lg font-semibold">{object.objectName}</h2>
                    <p className="text-sm text-gray-600">
                      {expandedObjectId === object.id
                        ? "Click to collapse details"
                        : "Click to view details"}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    {/* Edit Button */}
                    <button
                      onClick={() => handleEditClick(object)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FiEdit size={20} />
                    </button>

                    {/* Delete Button */}
                    <button
                      onClick={() => handleDeleteClick(object.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FiTrash2 size={20} />
                    </button>
                  </div>
                </div>

                {expandedObjectId === object.id && (
                  <div className="mt-4">
                    {object.fields && object.fields.length > 0 ? (
                      object.fields.map((field, idx) => (
                        <div key={idx} className="mb-4">
                          <label className="block mb-2 capitalize">
                            {field.key}
                          </label>

                          {field.type === "radio" &&
                            Array.isArray(field.radioValues) && (
                              <div>
                                {field.radioValues.map((value, index) => (
                                  <div key={index} className="inline-block mr-4">
                                    <input
                                      type="radio"
                                      id={`${object.id}-${field.key}-${value}`}
                                      name={`${object.id}-${field.key}`}
                                      value={value}
                                      className="mr-2"
                                    />
                                    <label
                                      htmlFor={`${object.id}-${field.key}-${value}`}
                                    >
                                      {value}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )}

                          {field.type === "checkbox" &&
                            Array.isArray(field.checkboxValues) && (
                              <div>
                                {field.checkboxValues.map((value, index) => (
                                  <div key={index} className="inline-block mr-4">
                                    <input
                                      type="checkbox"
                                      id={`${object.id}-${field.key}-${value}`}
                                      value={value}
                                      className="mr-2"
                                    />
                                    <label
                                      htmlFor={`${object.id}-${field.key}-${value}`}
                                    >
                                      {value}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )}

                          {(field.type === "text" || field.type === "number") && (
                            <input
                              type={field.type}
                              className="w-full p-2 border rounded"
                            />
                          )}

                          {field.type === "date" && (
                            <input
                              type="date"
                              className="w-full p-2 border rounded"
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <p>No fields available for this object.</p>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No custom objects found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewCustomObjects;
