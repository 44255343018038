import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { logUserActivity } from "../../services/logUserActivity";
import CustomDataTable from "../../components/CustomDataTable";

const ViewCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("permissions")) || {};

  const canAdd = permissions.CategoryAdd || false;
  const canEdit = permissions.CategoryUpdate || false;
  const canDelete = permissions.CategoryDelete || false;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const categoriesCollectionRef = collection(db, "Categories");
        const categorySnapshot = await getDocs(categoriesCollectionRef);
        const categoriesData = categorySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1, // Add serial number dynamically
          ...doc.data(),
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Failed to fetch categories");
        setError("Failed to fetch categories");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this data?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "Categories", id));
        setCategories(categories.filter((item) => item.id !== id));
        logUserActivity("User Deleted Categories");
      } catch (error) {
        console.error("Error deleting data: ", error);
        setError("Failed to delete data.");
      }
    }
  };

  // Define columns dynamically based on permissions
  const columns = [
    {
      field: "serialNo",
      headerName: "الرقم التسلسلي",
      flex: 1,
      headerClassName: "header",
    
    },
    {
      field: "programName",
      headerName: "اسم البرنامج",
      flex: 1,
      headerClassName: "header",
     
    },
    {
      field: "categoryName",
      headerName: "اسم الفئة",
      flex: 1,
      headerClassName: "header",
    
    },
    ...(canEdit || canDelete
      ? [
          {
            field: "action",
            headerName: "الإجراءات",
            flex: 1,
            headerClassName: "header",
           
            renderCell: (params) => (
              <div className="flex items-center gap-2 h-full">
                {canEdit && (
                  <Link
                    to={`/update-category/${params.row.id}`} // Adjust path accordingly
                    className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
                  >
                    <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
                  </Link>
                )}
                {canDelete && (
                  <div
                    onClick={() => handleDelete(params.row.id)}
                    className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
                  >
                    <FaTrash className="text-white h-4 w-4" />
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []), // If no permissions, exclude the action column
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl text-secondaryColor">جميع الفئات</h1>
        {canAdd && (
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
            <Link to="/add-category">أضف فئة</Link>
          </button>
        )}
      </div>

      <CustomDataTable
        rows={categories}
        columns={columns}
        loading={loading}
        error={error}
        noDataMessage="لم يتم العثور على بيانات"
        loadingMessage="جاري التحميل..."
      />

   
   
    </div>
  );
};

export default ViewCategories;
