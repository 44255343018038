import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { Controller, useForm } from "react-hook-form";
import SearchableDropdown from "../../components/fields/SearchableDropdown";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";

const AddCategory = () => {
  const { control, setValue, handleSubmit, reset } = useForm(); // Added react-hook-form support
  const [programTypes, setProgramTypes] = useState([]);
  const [selectedProgramType, setSelectedProgramType] = useState("");
  const [categoryName, setCategoryName] = useState(""); // State for category input

  const navigate = useNavigate();

  // Fetch program types from Firestore
  useEffect(() => {
    const fetchProgramType = async () => {
      try {
        const programTypesCollectionRef = collection(db, "ProgramType");
        const programTypeSnapshot = await getDocs(programTypesCollectionRef);
        const programTypesData = programTypeSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProgramTypes(programTypesData);
      } catch (error) {
        console.error("Error fetching program types:", error);
        toast.error("Failed to fetch program types");
      }
    };

    fetchProgramType();
  }, []);

  // Sync selectedProgramType with react-hook-form
  useEffect(() => {
    setValue("baseFields.selectedProgramType", selectedProgramType);
  }, [selectedProgramType, setValue]);

  const programOptions = programTypes.map((program) => ({
    value: program.id,
    label: program.programtype,
  }));

  // Handle category submission
  const onSubmit = async () => {
    if (!selectedProgramType || !categoryName) {
      toast.error("Please select a program type and enter a category name");
      return;
    }

    // Find the program name based on the selected program type
    const selectedProgram = programTypes.find(
      (program) => program.id === selectedProgramType
    );
    const programName = selectedProgram ? selectedProgram.programtype : "";

    try {
      const categoriesCollectionRef = collection(db, "Categories");
      await addDoc(categoriesCollectionRef, {
        programTypeId: selectedProgramType,
        categoryName,
        programName, // Store the program name along with category
      });

      toast.success("Category added successfully");
      logUserActivity("User Added Category");
      navigate("/view-categories");
      reset(); // Reset form inputs
      setCategoryName(""); // Clear category input
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Failed to add category");
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl text-secondaryColor mb-6">إنشاء فئة</h1>
      </div>

      <div className="rounded-lg shadow">
        <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
          {/* Program Type Dropdown */}
          <div className="mb-4">
            <Controller
              name="programtype"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={programOptions}
                  selectedValue={selectedProgramType}
                  setSelectedValue={(value) => {
                    setSelectedProgramType(value);
                    field.onChange(value); // Ensure the value is passed to react-hook-form
                  }}
                  placeholder="حدد نوع البرنامج"
                />
              )}
            />
          </div>

          {/* Input for Category Name */}
          <div className="mb-4">
            <input
              type="text"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="أدخل اسم الفئة"
            />
          </div>

          {/* Submit Button */}
          <button
            onClick={handleSubmit(onSubmit)}
            className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
          >
           أضف فئة
          </button>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
