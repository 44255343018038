import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection, Timestamp, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";

// Define schema for 'role' and 'permissions'
const dataSchema = z.object({
  role: z
    .string()
    .min(1, { message: "Role is required" })
    .max(30, { message: "Role cannot exceed 30 characters" }),
  permissions: z.record(z.boolean()).optional(), // Allowing 'permissions' as an optional record
});

const AddRole = () => {
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState({}); // Permissions as object

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(dataSchema),
  });

  const navigate = useNavigate();

  // Fetch permissions from Firestore
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "permissions"));
        const permissionsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPermissions(permissionsList);

        // Initialize `selectedPermissions` with `false` for each permission
        const initialPermissions = permissionsList.reduce((acc, permission) => {
          acc[permission.featureName] = false;
          return acc;
        }, {});
        setSelectedPermissions(initialPermissions);
      } catch (error) {
        console.error("Error fetching permissions: ", error);
        toast.error("Failed to fetch permissions.");
      }
    };

    fetchPermissions();
  }, []);

  const onSubmit = async (data) => {
    try {
      // Prepare the role data with timestamps and selected permissions
      const roleData = {
        role: data.role,
        permissions: selectedPermissions, // Use permission object
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };

      const docRef = await addDoc(collection(db, "roles"), roleData);
      console.log("Document written with ID: ", docRef.id);
      toast.success("Role successfully added!");

      logUserActivity("User added a new role");
      navigate("/role");
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error("Failed to add role.");
    }
  };

  const handleCheckboxChange = (featureName) => {
    setSelectedPermissions((prev) => ({
      ...prev,
      [featureName]: !prev[featureName], // Toggle the value
    }));
  };

  // Group permissions based on the first part of the feature name
  const groupedPermissions = permissions.reduce((acc, permission) => {
    const group = permission.featureName.split(/(?=[A-Z])/)[0]; // Extract first part (e.g., 'Bank' from 'BankAdd')
    if (!acc[group]) acc[group] = [];
    acc[group].push(permission);
    return acc;
  }, {});

  return (
    <>
      <div>
        <h1 className="font-bold text-xl text-secondaryColor mb-6">إنشاء دور</h1>
      </div>

      

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <InputField
              label="دور"
              error={errors.role?.message}
              register={register}
              name="role"
            />

            <div>
              <h2 className="font-bold text-lg">حدد الأذونات:</h2>
              {Object.keys(groupedPermissions).map((group) => (
                <div
                  key={group}
                  className="border rounded-lg p-4 shadow-sm mt-4"
                >
                  <h3 className="font-semibold text-md mb-2">{group}</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {groupedPermissions[group].map((permission) => (
                      <div key={permission.id} className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={selectedPermissions[permission.featureName] || false}
                          onChange={() => handleCheckboxChange(permission.featureName)}
                          className="form-checkbox"
                        />
                        <label className="font-medium">{permission.featureName}</label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              أضف دور
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddRole;
