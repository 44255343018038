import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase";
import { toast } from "react-toastify";

const SingleFProgramsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { programs, loanApplicationData } = location.state || {};
  const [uploadedDocuments, setUploadedDocuments] = useState({});
  const [loading, setLoading] = useState(false);
  const [programDocuments, setProgramDocuments] = useState({});
  const [bankEmployees, setBankEmployees] = useState([]);
  const [documentNames, setDocumentNames] = useState({});

  const handleFieldChange = (objectId, fieldKey, newValue) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [objectId]: {
        ...prevValues[objectId],
        [fieldKey]: newValue,
      },
    }));
  };


  const handleRadioChange = (objectId, fieldKey, value) => {
    setFieldValues(prevValues => ({
      ...prevValues,
      [objectId]: {
        ...prevValues[objectId],
        [fieldKey]: value,
      },
    }));
  };


  const handleFieldCheckboxChange = (objectId, fieldKey, value) => {
    setFieldValues(prevValues => {
      const currentValues = Array.isArray(prevValues[objectId]?.[fieldKey]) 
        ? prevValues[objectId][fieldKey] 
        : [];
      
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter(v => v !== value)
        : [...currentValues, value];

      return {
        ...prevValues,
        [objectId]: {
          ...prevValues[objectId],
          [fieldKey]: updatedValues
        }
      };
    });
  };

  const [fieldValues, setFieldValues] = useState({});

  // Initialize programDocuments state for each bank and fetch document names
  useEffect(() => {
    const initialProgramDocs = {};
    programs?.forEach((program) => {
      initialProgramDocs[program.bank] = {};
    });
    setProgramDocuments(initialProgramDocs);

    // Fetch document names from Firestore
    const fetchDocumentNames = async () => {
      try {
        const docsRef = collection(db, "RequiredDocuments");
        const docsSnapshot = await getDocs(docsRef);
        const names = {};
        docsSnapshot.forEach((doc) => {
          names[doc.id] = doc.data().name;
        });
        setDocumentNames(names);
      } catch (error) {
        console.error("Error fetching document names:", error);
      }
    };

    fetchDocumentNames();
  }, [programs]);

  // Collect all unique required documents across all programs
  const allRequiredDocuments = new Set();
  programs?.forEach((program) => {
    program.required_documents?.forEach((document) => {
      allRequiredDocuments.add(document);
    });
  });

  // Get display name for a document
  const getDocumentDisplayName = (documentId) => {
    // Try to get the name from Firestore document names
    if (documentNames[documentId]) {
      return documentNames[documentId];
    }
    // Fallback: Convert ID to readable format
    return documentId
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Initialize programDocuments state for each bank
  useEffect(() => {
    const initialProgramDocs = {};
    programs?.forEach((program) => {
      initialProgramDocs[program.bank] = {};
    });
    setProgramDocuments(initialProgramDocs);
  }, [programs]);

  useEffect(() => {
    if (programs) {
      const initialValues = {};
      programs.forEach((program) => {
        if (program.program_specific_details) {
          Object.keys(program.program_specific_details).forEach((objectId) => {
            const details = program.program_specific_details[objectId];
            initialValues[objectId] = {};
            if (details.fields) {
              details.fields.forEach((field) => {
                initialValues[objectId][field.key] = "";
              });
            }
          });
        }
      });
      setFieldValues(initialValues);
    }
  }, [programs]);

  const handleFileUpload = (documentId, e) => {
    const file = e.target.files[0];
    if (file) {
      const documentName = getDocumentDisplayName(documentId);
      setUploadedDocuments(prev => ({
        ...prev,
        [documentId]: {
          file,
          name: file.name,
          documentName // Store the display name
        }
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const storage = getStorage();
      const applicationId = `CBF-AP-${(Math.random() * 10000).toFixed(0)}`;
      const userId = loanApplicationData?.userId;
      const userName = loanApplicationData?.userName;
  
      if (!userId) {
        throw new Error("User ID is missing.");
      }
  
      const totalLoanAmount = programs.reduce(
        (total, program) => total + parseFloat(loanApplicationData?.loanAmount || 0),
        0
      );
  
      // Create bank applications with document uploads
      const bankApplications = await Promise.all(
        programs.map(async (program, index) => {
          const selectedBank = program.bank; // Use the program's selected bank
          const bankApplicationId = `bank_app_${Date.now()}_${index}`;
          const bankName = selectedBank?.bankName || "Unknown Bank";
  
          const loanDocuments = [];
          const programSpecificDetails = {};
          Object.keys(program.program_specific_details).forEach((objectId) => {
            const objectDetails = program.program_specific_details[objectId];
            if (objectDetails.isRequired) {
              programSpecificDetails[objectId] = {
                ...objectDetails,
                ...fieldValues[objectId], // Merge the field values
              };
            }
          });
  
          // Process documents for the selected bank
          if (program.required_documents) {
            for (const docName of program.required_documents) {
              const uploadedFile = uploadedDocuments[docName]?.file;
              if (uploadedFile) {
                const storageRef = ref(
                  storage,
                  `loanApplications/${applicationId}/banks/${bankName}/documents/${docName}`
                );
                const snapshot = await uploadBytes(storageRef, uploadedFile);
                const url = await getDownloadURL(snapshot.ref);
  
                loanDocuments.push({
                  documentName: docName,
                  status: "Submitted",
                  url,
                  fileName: uploadedFile.name,
                });
              }
            }
          }
  
          return {
            bankApplicationId,
            bank: bankName,
            loanAmount: loanApplicationData?.loanAmount || 0,
            eligibilityStatus: loanApplicationData?.eligibilityStatus,
            approvalStatus: loanApplicationData?.approvalStatus,
            approvalDate: loanApplicationData?.eligibilityStatus
              ? null
              : new Date().toISOString(),
            programSpecificDetails,
            loanDocuments,
            history: [
              {
                timestamp: new Date().toISOString(),
                action: "Submitted Application",
                status: loanApplicationData?.approvalStatus,
                comments: loanApplicationData?.eligibilityStatus
                  ? "Application submitted successfully."
                  : "User did not meet eligibility criteria.",
              },
            ],
            assignedBankEmployee:
              bankEmployees.find(
                (emp) => emp.bankId === selectedBank?.id
              ) || null,
          };
        })
      );
  
      // Create final loan application document
      const loanApplication = {
        applicationId,
        userId,
        userName,
        loanProgram: programs[0]?.loan_program_name || "Unknown Program",
        totalLoanAmount,
        applicationDate: new Date().toISOString(),
        status: "In Progress",
        bankApplications,
      };
  
      // Save to Firestore
      await setDoc(doc(db, "LoanApplication", applicationId), loanApplication);
  
      toast.success("Loan application submitted successfully!");
      navigate("/loan-application");
    } catch (error) {
      console.error("Error submitting loan application:", error);
      toast.error("Error submitting loan application. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  

  const [objects, setObjects] = useState([]);

  // Initialize programDocuments state for each bank
  useEffect(() => {
    const initialProgramDocs = {};
    programs?.forEach((program) => {
      initialProgramDocs[program.bank] = {};
    });
    setProgramDocuments(initialProgramDocs);

    // Fetch employees for each bank
    const fetchBankEmployees = async () => {
      try {
        setLoading(true);
        const banksCollectionRef = collection(db, "Banks");
        const banksSnapshot = await getDocs(banksCollectionRef);

        const employeePromises = banksSnapshot.docs.map(async (bankDoc) => {
          const bankId = bankDoc.id;
          const bankEmployeeCollectionRef = collection(
            db,
            "Banks",
            bankId,
            "BankEmployee"
          );
          const employeeSnapshot = await getDocs(bankEmployeeCollectionRef);
          return employeeSnapshot.docs.map((employeeDoc) => ({
            id: employeeDoc.id,
            bankId,
            ...employeeDoc.data(),
          }));
        });

        const allEmployees = (await Promise.all(employeePromises)).flat();
        setBankEmployees(allEmployees);
      } catch (error) {
        console.error("Error fetching employees:", error);
        toast.error("Failed to fetch employees.");
      } finally {
        setLoading(false);
      }
    };

    fetchBankEmployees();
  }, [programs]);

  console.log("programs", programs);

  return (
    <div className="rounded-lg shadow-lg p-8">
      {programs ? (
        <div>
          <h1 className="text-2xl">Required Documents</h1>
          {allRequiredDocuments.size > 0 ? (
            <div className="flex flex-wrap -mx-2">
              {[...allRequiredDocuments].map((documentId, index) => (
                <div key={index} className="w-full md:w-1/2 px-2 mb-4">
                  <label className="block font-medium mb-2">
                    {getDocumentDisplayName(documentId)}:
                  </label>
                  <input
                    type="file"
                    accept="application/pdf,image/*"
                    onChange={(e) => handleFileUpload(documentId, e)}
                    className="border p-1 rounded-lg w-full file:bg-gradient-to-r file:from-secondaryColor file:to-primaryColor file:text-white file:cursor-pointer file:border-none file:rounded-sm"
                  />
                  {uploadedDocuments[documentId] && (
                    <p className="text-sm text-gray-600 mt-2">
                      Selected file: {uploadedDocuments[documentId].name}
                    </p>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p>No required documents found for this program.</p>
          )}
        </div>
      ) : (
        <p>No programs selected.</p>
      )}

      <h1 className="text-2xl">Program Details</h1>

      {programs.map((program, programIndex) => (
        <div key={programIndex} className="border-b border-gray-200 pb-4 mb-4">
          <h3 className="font-bold text-secondaryColor mb-2">
            Program: {program.loan_program_name}
          </h3>

          {program.program_specific_details &&
            Object.keys(program.program_specific_details).length > 0 && (
              <div className="mt-6">
                {Object.entries(program.program_specific_details).map(
                  ([objectId, objectFields]) => {
                    const objectDef =
                      program.program_specific_details[objectId];

                    if (!objectDef) return null; // Skip if objectDef is not found

                    return (
                      <div key={objectId} className="mb-4">
                        <h3 className="text-xl flex items-center">
                          {objectDef.objectName}
                          {objectDef.isRequired && (
                            <span className="text-red-500 text-sm ml-2">
                              (Required)
                            </span>
                          )}
                        </h3>

                        {/* Render object fields */}
                        {objectDef.fields &&
                          objectDef.fields.map((field) => {
                            const fieldValue = objectFields[field.key];

                            return (
                              <div key={field.id} className="mb-4">
                                <label className="block mb-2 capitalize">
                                  {field.key.replace(/_/g, " ")}
                                  {objectDef.isRequired && (
                                    <span className="text-red-500 ml-1">*</span>
                                  )}
                                </label>

                                {/* Render based on field type */}
                                {field.type === "radio" &&
                                  Array.isArray(field.radioValues) && (
                                    <div>
                                      {field.radioValues.map((value, idx) => (
                                        <div
                                          key={idx}
                                          className="inline-block mr-4"
                                        >
                                           <input
                                          type="radio"
                                          id={`${objectId}-${field.key}-${value}`}
                                          name={`${objectId}-${field.key}`}
                                          value={value}
                                          checked={fieldValues[objectId]?.[field.key] === value}
                                          onChange={() => handleRadioChange(objectId, field.key, value)}
                                          className="mr-2"
                                        />
                                          <label
                                            htmlFor={`${objectId}-${field.key}-${value}`}
                                          >
                                            {value}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                {field.type === "checkbox" &&
                                  Array.isArray(field.checkboxValues) && (
                                    <div>
                                      {field.checkboxValues.map(
                                        (value, idx) => (
                                          <div
                                            key={idx}
                                            className="inline-block mr-4"
                                          >
                                            <input
                                          type="checkbox"
                                          id={`${objectId}-${field.key}-${value}`}
                                          value={value}
                                          checked={Array.isArray(fieldValues[objectId]?.[field.key]) &&
                                            fieldValues[objectId][field.key].includes(value)}
                                          onChange={() => handleFieldCheckboxChange(objectId, field.key, value)}
                                          className="mr-2"
                                        />
                                            <label
                                              htmlFor={`${objectId}-${field.key}-${value}`}
                                            >
                                              {value}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                {(field.type === "text" ||
                                  field.type === "number") && (
                                  <input
                                    key={field.id}
                                    type={field.type}
                                    value={
                                      fieldValues[objectId]?.[field.key] || ""
                                    }
                                    onChange={(e) =>
                                      handleFieldChange(
                                        objectId,
                                        field.key,
                                        e.target.value
                                      )
                                    }
                                    className="w-full p-2 border rounded"
                                  />
                                )}

                                {field.type === "date" && (
                                  <input
                                    type="date"
                                    value={fieldValue || ""}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        objectId,
                                        field.key,
                                        e.target.value
                                      )
                                    }
                                    className="w-full p-2 border rounded"
                                  />
                                )}
                              </div>
                            );
                          })}
                      </div>
                    );
                  }
                )}
              </div>
            )}

          {/* Display selected bank */}
          <p>Bank: {program.bank?.bankName}</p>

          {/* Employee Selection Dropdown */}
          <div className="mb-4">
            <label
              htmlFor={`employee-${programIndex}`}
              className="block text-sm font-medium text-gray-700"
            >
              Select Employee:
            </label>
            <select
              id={`employee-${programIndex}`}
              className="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-secondaryColor focus:border-secondaryColor sm:text-sm rounded-md"
            >
              <option value="">-- Select an Employee --</option>
              {bankEmployees
                .filter((employee) => employee.bankId === program.bank)
                .map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.bankEmployeeName}
                  </option>
                ))}
            </select>
          </div>
        </div>
      ))}

      {/* Submit Button */}
      <div className="mt-4">
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-gradient-to-r from-secondaryColor to-primaryColor  text-white font-bold rounded-md hover:bg-primaryColorDark focus:outline-none focus:ring-2 focus:ring-primaryColor focus:ring-opacity-50"
        >
          {loading ? "Loading..." : " Submit Loan Application"}
        </button>
      </div>
    </div>
  );
};

export default SingleFProgramsPage;
