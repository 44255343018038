import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash, FaPencilAlt, FaEye } from "react-icons/fa"; // Import FaEye for view icon
import { Link } from "react-router-dom";
import { db } from "../../firebase"; // Adjust the import based on your project structure
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";
import CustomDataTable from "../../components/CustomDataTable";

const ViewRole = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const columns = [
    {
      field: "serialNo",
      headerName: "الرقم التسلسلي",
      flex: 1,
      headerClassName: "header",
     
    },
    {
      field: "role",
      headerName: "دور",
      flex: 1,
      headerClassName: "header",
  
    },
    {
      field: "action",
      headerName: "الإجراءات",
    
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full">
          {/* View Icon */}
          <Link
            to={`/view-role/${params.row.id}`} // Adjust the path based on your routing setup
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <FaEye className="text-white cursor-pointer h-4 w-4" />
          </Link>
          {/* Edit Icon */}
          <Link
            to={`/update-role/${params.row.id}`}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
          </Link>
          {/* Delete Icon */}
          <div
            onClick={() => handleDelete(params.row.id)}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
          >
            <FaTrash className="text-white h-4 w-4" />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "roles"));
        const roles = querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1,
          ...doc.data(),
        }));
        setData(roles);
        logUserActivity("User Viewed roles");
      } catch (error) {
        console.error("Error fetching roles: ", error);
        setError("Failed to load roles.");
      } finally {
        setLoading(false);
      }
    };

    fetchRoles();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this role?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "roles", id));
        setData(data.filter((item) => item.id !== id));
        logUserActivity("User Deleted role");
      } catch (error) {
        console.error("Error deleting role: ", error);
        setError("Failed to delete role.");
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-bold text-xl text-secondaryColor">جميع الأدوار</h1>
        </div>
        <div>
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
            <Link to="/add-role">أضف دور</Link>
          </button>
        </div>
      </div>

      <CustomDataTable
        rows={data}
        columns={columns}
        loading={loading}
        error={error}
        noDataMessage="لم يتم العثور على بيانات"
        loadingMessage="جاري التحميل..."
      />
    </div>
  );
};

export default ViewRole;
