import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash, FaPencilAlt, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import CustomDataTable from "../../components/CustomDataTable";

const Banks = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  const canAdd = permissions.BankAdd || false;
  const canEdit = permissions.BankUpdate || false;
  const canDelete = permissions.BankDelete || false;
 


  const fetchBanks = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Banks"));

      const banks = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        serialNo: index + 1,
        ...doc.data(),
      }));
      setData(banks);
    } catch (error) {
      console.error("Error fetching Banks: ", error);
      setError("Failed to load Banks.");
      logUserActivity("User Viewed Banks");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  const handleDelete = async (id) => {
    if (!canDelete) return;
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this bank?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "Banks", id));
        setData(data.filter((item) => item.id !== id));
        toast.success("Bank deleted successfully!");
        logUserActivity("User Deleted Bank");
      } catch (error) {
        console.error("Error deleting bank data: ", error);
        setError("Failed to delete bank data.");
        toast.error("Failed to delete bank data.");
      }
    }
  };

  // Define columns
  const columns = [
    {
      field: "serialNo",
      headerName: "الرقم التسلسلي",
      flex: 1,
      headerClassName: "header",
      
    },
    {
      field: "name",
      headerName: "اسم البنك",
      flex: 1,
      headerClassName: "header",
    
    },
    {
      field: "headquarters",
      headerName: "موقع المقر الرئيسي للبنك",
      flex: 1,
      headerClassName: "header",
    
    },
    {
      field: "contactNumber",
      headerName: "رقم الاتصال",
      flex: 1,
      headerClassName: "header",
    
    },
    {
      field: "type",
      headerName: "يكتب",
      flex: 1,
      headerClassName: "header",
     
    },
  ];

  if (canEdit || canDelete) {
    columns.push({
      field: "action",
      headerName: "الإجراءات",
      flex: 1,
      headerClassName: "header",
     
      renderCell: (params) =>
        params?.row ? (
          <div className="flex items-center gap-2 h-full">
            <Link
              to={`/view-bank/${params.row.id}`} // Replace this path with your actual view route
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
            >
              <FaEye className="text-white cursor-pointer h-4 w-4" />{" "}
              {/* View Icon */}
            </Link>
            {canEdit && (
              <Link
                to={`/update-bank/${params.row.id}`} // Adjust path accordingly
                className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
              >
                <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />{" "}
                {/* Edit Icon */}
              </Link>
            )}
            {canDelete && (
              <div
                onClick={() => handleDelete(params.row.id)}
                className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
              >
                <FaTrash className="text-white h-4 w-4" /> {/* Delete Icon */}
              </div>
            )}
          </div>
        ) : null,
    });
  }

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl text-secondaryColor">جميع البنوك</h1>
        {canAdd && (
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 py-2 flex gap-2 items-center border-themeColor text-sm font-semibold">
            <Link to="/add-bank">أضف البنك</Link>
          </button>
        )}
      </div>

      <CustomDataTable
        rows={data}
        columns={columns}
        loading={loading}
        error={error}
        noDataMessage="لم يتم العثور على بيانات"
        loadingMessage="جاري التحميل..."
      />
  
     
    </div>
  );
};

export default Banks;
