import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from "../assets/cbf logo مفرغ.png";
import { useUserAuth } from "../context/UserAuthContext"; // Import the custom hook for auth

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const { logOut, user } = useUserAuth(); // Assuming `user` contains the logged-in user's details
  const navigate = useNavigate(); // To redirect after logout

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await logOut(); // Call the logOut function from context
      navigate('/signIn'); // Redirect to the login page after logging out
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  // Get the first letter of the user's name or email
  const getInitial = () => {
    if (user && user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    } else if (user && user.email) {
      return user.email.charAt(0).toUpperCase();
    }
    return "?"; // Fallback if no user data is available
  };

  return (
    <nav className="border-b bg-white border-gradient-to-r from-secondaryColor to-primaryColor w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <Link to="/" className="text-white text-lg font-bold">
              <img src={logoImg} alt="logoImg" width={60} />
            </Link>
          </div>

          <div className="flex items-center">
            <div className="relative">
              <div
                className="w-8 h-8 rounded-full bg-secondaryColor text-white flex items-center justify-center cursor-pointer"
                onClick={toggleProfileMenu}
              >
                {getInitial()}
              </div>
              {isProfileMenuOpen && (
                <div className="absolute left-0 w-48 mt-2 bg-white border rounded-md shadow-lg z-50">
                  <Link to="/signIn" className="block px-4 py-2 text-left text-gray-800 hover:bg-gray-200">Create an Account</Link>
                 
                  <button
                    onClick={handleLogout}
                    className="block text-left w-full  px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Sign Out
                  </button>
                </div>
              )}
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded={isOpen}
              >
                <span className="sr-only">Open main menu</span>
                {isOpen ? (
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
