import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";

const EditCustomObjects = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { object } = location.state || {};

  const [editedObject, setEditedObject] = useState(
    object || {
      id: "",
      objectName: "",
      fields: [],
    }
  );

  const updateObjectName = (name) => {
    setEditedObject((prev) => ({
      ...prev,
      objectName: name,
    }));
  };

  const addField = () => {
    const newField = {
      id: uuidv4(),
      key: "",
      type: "text",
      radioValues: [],
      checkboxValues: [],
    };

    setEditedObject((prev) => ({
      ...prev,
      fields: [...prev.fields, newField],
    }));
  };

  const updateField = (fieldId, key, value) => {
    setEditedObject((prev) => ({
      ...prev,
      fields: prev.fields.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              [key]: value,
              ...(key === "type" && {
                radioValues: value === "radio" ? [""] : [],
                checkboxValues: value === "checkbox" ? [""] : [],
              }),
            }
          : field
      ),
    }));
  };

  const addOptionValue = (fieldId, type) => {
    setEditedObject((prev) => ({
      ...prev,
      fields: prev.fields.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              [type === "radio" ? "radioValues" : "checkboxValues"]: [
                ...(type === "radio"
                  ? field.radioValues
                  : field.checkboxValues),
                "",
              ],
            }
          : field
      ),
    }));
  };

  const updateOptionValue = (fieldId, type, index, value) => {
    setEditedObject((prev) => ({
      ...prev,
      fields: prev.fields.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              [type === "radio" ? "radioValues" : "checkboxValues"]: field[
                type === "radio" ? "radioValues" : "checkboxValues"
              ].map((v, i) => (i === index ? value : v)),
            }
          : field
      ),
    }));
  };

  const removeOptionValue = (fieldId, type, index) => {
    setEditedObject((prev) => ({
      ...prev,
      fields: prev.fields.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              [type === "radio" ? "radioValues" : "checkboxValues"]: field[
                type === "radio" ? "radioValues" : "checkboxValues"
              ].filter((_, i) => i !== index),
            }
          : field
      ),
    }));
  };

  const saveAllObjects = async () => {
    try {
      // Validate object name
      if (!editedObject.objectName.trim()) {
        alert("Please provide an object name!");
        return;
      }

      // Validate fields and their options
      if (editedObject.fields.some((field) => !field.key.trim())) {
        alert("All fields must have names!");
        return;
      }

      const invalidField = editedObject.fields.find((field) => {
        if (
          field.type === "radio" &&
          (!field.radioValues.length ||
            field.radioValues.some((v) => !v.trim()))
        ) {
          return true;
        }
        if (
          field.type === "checkbox" &&
          (!field.checkboxValues.length ||
            field.checkboxValues.some((v) => !v.trim()))
        ) {
          return true;
        }
        return false;
      });

      if (invalidField) {
        alert(
          `Please provide valid options for field "${invalidField.key.trim()}".`
        );
        return;
      }

      // Ensure the object ID exists
      if (!editedObject.id) {
        alert("Invalid object ID. Cannot update an object without an ID.");
        return;
      }

      // Reference to Firestore document
      const docRef = doc(db, "CustomObjects", editedObject.id);

      // Fetch the existing document
      const docSnap = await getDoc(docRef);

      console.log(docSnap)

      if (!docSnap.exists()) {
        alert("The specified object does not exist.");
        return;
      }

      // Prepare updated data
      const updatedObject = {
        objectName: editedObject.objectName,
        fields: editedObject.fields,
        updatedAt: new Date().toISOString(),
      };

      // Update Firestore document
      await updateDoc(docRef, updatedObject);

   
      toast.success("Field Updated Successfully!")
      navigate("/view-fields"); // Navigate back to the view page
    } catch (error) {
      console.error("Error updating object:", error);
      alert("Failed to update the object. Please try again.");
    }
  };

  useEffect(() => {
    console.log("Object passed via location:", location.state);
    console.log("Object ID:", editedObject.id);
  }, [editedObject]);

  

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-6">
        <h1 className="font-bold text-xl">Edit Custom Object</h1>
        <button
          onClick={() => navigate(-1)}
          className="text-gray-600 hover:text-gray-800"
        >
          ← Back
        </button>
      </div>

      <div className="p-4 border rounded-md shadow-md">
        <input
          type="text"
          placeholder="Object Name"
          value={editedObject.objectName}
          onChange={(e) => updateObjectName(e.target.value)}
          className="w-full px-2 py-1 mb-4 rounded-md border border-neutral-300"
          required
        />

        <button
          onClick={addField}
          className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600"
        >
          + Add Field
        </button>

        {editedObject.fields.map((field) => (
          <div key={field.id} className="mt-4 p-4 border rounded-md bg-gray-50">
            <div className="flex items-center gap-2 mb-2">
              <input
                type="text"
                placeholder="Field Name"
                value={field.key}
                onChange={(e) => updateField(field.id, "key", e.target.value)}
                className="w-3/5 px-2 py-1 rounded-md border border-neutral-300"
              />

              <select
                value={field.type}
                onChange={(e) => updateField(field.id, "type", e.target.value)}
                className="w-1/4 px-1 py-1 border rounded-md"
              >
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="radio">Radio</option>
                <option value="checkbox">Checkbox</option>
              </select>

              <button
                onClick={() =>
                  setEditedObject((prev) => ({
                    ...prev,
                    fields: prev.fields.filter((f) => f.id !== field.id),
                  }))
                }
                className="text-red-500 hover:text-red-700"
              >
                &#128465;
              </button>
            </div>

            {(field.type === "radio" || field.type === "checkbox") && (
              <div className="mt-2 pl-4">
                <p className="text-sm text-gray-600 mb-2">
                  {field.type === "radio"
                    ? "Radio Options"
                    : "Checkbox Options"}
                  :
                </p>
                {(field.type === "radio"
                  ? field.radioValues
                  : field.checkboxValues
                ).map((value, index) => (
                  <div key={index} className="flex items-center gap-2 mb-2">
                    <input
                      type="text"
                      value={value}
                      onChange={(e) =>
                        updateOptionValue(
                          field.id,
                          field.type,
                          index,
                          e.target.value
                        )
                      }
                      placeholder={`Option ${index + 1}`}
                      className="px-2 py-1 rounded-md border border-neutral-300"
                    />
                    <button
                      onClick={() =>
                        removeOptionValue(field.id, field.type, index)
                      }
                      className="text-red-500 hover:text-red-700"
                    >
                      &#128465;
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => addOptionValue(field.id, field.type)}
                  className="text-sm text-blue-500 hover:text-blue-700"
                >
                  + Add Option
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-6">
        <button
          onClick={saveAllObjects}
          className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 hover:shadow-lg"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default EditCustomObjects;
