import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import InputField from "../../components/fields/TextInput";
import SearchableDropdown from "../../components/fields/SearchableDropdown";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { idID } from "@mui/material/locale";

// Define the schema for validation
const updateEmployeeSchema = z.object({
  bankEmployeeName: z
    .string()
    .min(1, { message: "Bank Employee Name is required" })
    .max(30, { message: "Bank Employee Name cannot exceed 30 characters" }),
  employeeDepartment: z
    .string()
    .min(1, { message: "Employee Department is required" })
    .max(50, { message: "Employee Department cannot exceed 50 characters" }),
  contactNumber: z
    .string()
    .min(1, { message: "Contact Number is required" })
    .max(15, { message: "Contact Number cannot exceed 15 characters" }),
  bankEmployeeEmail: z.string().email("Invalid email format").optional(),
});

const UpdateBankEmployee = () => {
  const { bankId, id } = useParams(); // Get bankId and employeeId from URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [banks, setBanks] = useState([]); // List of banks
  const [selectedBank, setSelectedBank] = useState(""); // Selected bank ID

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(updateEmployeeSchema),
  });

  // Fetch the current employee's data
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        setLoading(true);
        const employeeRef = doc(db, "Banks", bankId, "BankEmployee", id);
        const employeeSnap = await getDoc(employeeRef);

        if (employeeSnap.exists()) {
          const employeeData = employeeSnap.data();
          reset(employeeData); // Populate form with the employee's data
          setSelectedBank(bankId); // Set the current bank as selected
        } else {
          toast.error("Employee not found.");
          navigate("/bank-employee");
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
        toast.error("Failed to fetch employee data.");
        navigate("/bank-employee");
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeData();
  }, [bankId, id, navigate, reset]);

  // Fetch all banks for dropdown
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const banksRef = collection(db, "Banks");
        const banksSnap = await getDocs(banksRef);
        const banksData = banksSnap.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name, // Ensure you have a name field in your bank documents
        }));
        setBanks(banksData);
      } catch (error) {
        console.error("Error fetching banks:", error);
        toast.error("Failed to fetch banks.");
      }
    };

    fetchBanks();
  }, []);

  // Handle form submission
  const onSubmit = async (data) => {
    try {
      const employeeRef = doc(db, "Banks", selectedBank, "BankEmployee", id);
      await updateDoc(employeeRef, data);
      toast.success("Bank Employee Updated Successfully!");
      logUserActivity("User updated a bank employee");
      navigate("/bank-employee");
    } catch (error) {
      console.error("Error updating employee:", error);
      toast.error("Failed to update employee.");
    }
  };

  // Format banks for SearchableDropdown
  const bankOptions = banks.map((bank) => ({
    value: bank.id,
    label: bank.name,
  }));

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div>
        <h1 className="font-bold text-xl text-secondaryColor mb-6">
        تحديث موظف البنك
        </h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <div className="flex justify-center gap-6">
              {/* Bank Employee Name */}
              <div className="w-full">
                <InputField
                  label="اسم موظف البنك"
                  error={errors.bankEmployeeName?.message}
                  register={register}
                  name="bankEmployeeName"
                  placeholder="أدخل اسم الموظف"
                />
              </div>

              {/* Employee Department */}
              <div className="w-full">
                <InputField
                  label="قسم الموظفين"
                  error={errors.employeeDepartment?.message}
                  register={register}
                  name="employeeDepartment"
                  placeholder="أدخل القسم"
                />
              </div>
            </div>

            <div className="flex justify-center gap-6">
              {/* Contact Number */}
              <div className="w-full">
                <InputField
                  label="رقم الاتصال"
                  error={errors.contactNumber?.message}
                  register={register}
                  name="contactNumber"
                   placeholder="اتصال"
                />
              </div>

              {/* Bank Employee Email */}
              <div className="w-full">
                <InputField
                  label="Bank Employee Email"
                  error={errors.bankEmployeeEmail?.message}
                  register={register}
                  name="bankEmployeeEmail"
                  placeholder="أدخل البريد الإلكتروني"
                />
              </div>
            </div>

            {/* Select Bank using SearchableDropdown */}
            <div className="w-full">
              <Controller
                name="bank"
                control={control}
                render={({ field }) => (
                  <SearchableDropdown
                    options={bankOptions}
                    selectedValue={selectedBank}
                    setSelectedValue={(value) => {
                      setSelectedBank(value);
                      field.onChange(value); // Ensure the value is passed to react-hook-form
                    }}
                    placeholder="اختر البنك"
                  />
                )}
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
             تحديث موظف البنك
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateBankEmployee;
