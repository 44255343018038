import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";
import CustomDataTable from "../../components/CustomDataTable";

const ViewServicesProvider = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  const canAdd = permissions.ServiceProviderAdd || false;
  const canEdit = permissions.ServiceProviderUpdate || false;
  const canDelete = permissions.ServiceProviderDelete || false;


  // Dynamically set columns based on permissions
  const columns = [
    {
      field: "serialNo",
      headerName: "الرقم التسلسلي",
      flex: 1,
      headerClassName: "header",
   
    },
    {
      field: "programName",
      headerName: "اسم الخدمات",
      flex: 1,
      headerClassName: "header",
    
    },
    {
      field: "name",
      headerName: "اسم",
      flex: 1,
      headerClassName: "header",
    
    },
    {
      field: "email",
      headerName: "بريد إلكتروني",
      flex: 1,
      headerClassName: "header",
  
    },
    {
      field: "phoneNumber",
      headerName: "رقم التليفون",
      flex: 1,
      headerClassName: "header",
     
    },
    {
      field: "address",
      headerName: "عنوان",
      flex: 1,
      headerClassName: "header",
    
    },
    // Conditionally add the Action column if either canEdit or canDelete is true
    ...(canEdit || canDelete
      ? [
          {
            field: "action",
            headerName: "الإجراءات",
            flex: 1,
            headerClassName: "header",
        
            renderCell: (params) => (
              <div className="flex items-center gap-2 h-full">
                {/* Edit Button */}
                {canEdit && (
                  <Link
                    to={`/update-service-provider/${params.row.id}`}
                    className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
                  >
                    <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
                  </Link>
                )}

                {/* Delete Button */}
                {canDelete && (
                  <div
                    onClick={() => handleDelete(params.row.id)}
                    className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
                  >
                    <FaTrash className="text-white h-4 w-4" />
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "ServicesProvider"));
        const products = querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1,
          ...doc.data(),
        }));
        setData(products);
        logUserActivity("User Viewed Services Provider");
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this service?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "ServicesProvider", id));
        setData(data.filter((item) => item.id !== id));
        logUserActivity("User Deleted Services Provider");
      } catch (error) {
        console.error("Error deleting data: ", error);
        setError("Failed to delete data.");
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl text-secondaryColor">مقدم الخدمات</h1>

        {/* Add Button */}
        {canAdd && (
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
            <Link to="/add-services-provider">إضافة مزود الخدمة</Link>
          </button>
        )}
      </div>

   
        <CustomDataTable
        rows={data}
        columns={columns}
        loading={loading}
        error={error}
        noDataMessage="لم يتم العثور على بيانات"
        loadingMessage="جاري التحميل..."
      />
      
    </div>
  );
};

export default ViewServicesProvider;
