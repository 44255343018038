import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { Controller, useForm } from "react-hook-form";
import SearchableDropdown from "../../components/fields/SearchableDropdown";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/fields/TextInput";

const AddServicesProvider = () => {
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm(); // react-hook-form support
  const [services, setServices] = useState([]);
  const [selectedProgramType, setSelectedProgramType] = useState("");

  const navigate = useNavigate();

  // Fetch program types from Firestore
  useEffect(() => {
    const fetchProgramType = async () => {
      try {
        const servicesCollectionRef = collection(db, "Services");
        const servicesSnapshot = await getDocs(servicesCollectionRef);
        const servicesData = servicesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServices(servicesData);
      } catch (error) {
        console.error("Error fetching program types:", error);
        toast.error("Failed to fetch program types");
      }
    };

    fetchProgramType();
  }, []);

  const programOptions = services.map((program) => ({
    value: program.id,
    label: program.servicename,
  }));

  // Handle category submission
  const onSubmit = async (data) => {
    if (!selectedProgramType) {
      toast.error("Please select a service type");
      return;
    }

    // Find the program name based on the selected program type
    const selectedProgram = services.find(
      (program) => program.id === selectedProgramType
    );
    const programName = selectedProgram ? selectedProgram.servicename : "";

    try {
      const categoriesCollectionRef = collection(db, "ServicesProvider");
      await addDoc(categoriesCollectionRef, {
        ...data,
        programName, // Store the program name along with category
      });

      toast.success("ServicesProvider added successfully");
      logUserActivity("User Added Services Provider");
      navigate("/services-provider");
      reset(); // Reset form inputs
  
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Failed to add category");
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl text-secondaryColor mb-6">إنشاء مزود الخدمة</h1>
      </div>

      <div className="rounded-lg shadow">
        <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
          {/* Program Type Dropdown */}
          <div>
            <Controller
              name="servicetype"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={programOptions}
                  selectedValue={selectedProgramType}
                  setSelectedValue={(value) => {
                    setSelectedProgramType(value);
                    field.onChange(value); // Ensure the value is passed to react-hook-form
                  }}
                  placeholder="اختر نوع الخدمة"
                />
              )}
            />
          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              {/* Input for Category Name */}
              <InputField
                label="اسم"
                error={errors.name?.message}
                register={register}
                name="name"
                placeholder="أدخل الاسم"
              />
            </div>

            <div className="w-full">
              {/* Email Field */}
              <InputField
                label="بريد إلكتروني"
                error={errors.email?.message}
                register={register}
                name="email"
                placeholder="أدخل البريد الإلكتروني"
              />
            </div>
          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              {/* Phone Number Field */}
              <InputField
                label="رقم التليفون"
                error={errors.phoneNumber?.message}
                register={register}
                name="phoneNumber"
                placeholder="أدخل رقم الهاتف"
              />
            </div>

            <div className="w-full">
              {/* Address Field */}
              <InputField
                label="عنوان"
                error={errors.address?.message}
                register={register}
                name="address"
                placeholder="أدخل العنوان"
              />
            </div>
          </div>

          {/* Submit Button */}
          <button
            onClick={handleSubmit(onSubmit)}
            className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
          >
            إضافة مزود الخدمة
          </button>
        </div>
      </div>
    </>
  );
};

export default AddServicesProvider;
