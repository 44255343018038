import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase";
import { collection, setDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddCustomObjects = () => {
  const navigate = useNavigate();
  const [objects, setObjects] = useState([
    {
      id: uuidv4(),
      objectName: "",
      isRequired: false,
      fields: [],
    },
  ]);

  const addObjectHandler = () => {
    setObjects([
      ...objects,
      {
        id: uuidv4(),
        objectName: "",
        isRequired: false,
        fields: [],
      },
    ]);
  };

  const updateObjectName = (id, name) => {
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === id ? { ...obj, objectName: name } : obj
      )
    );
  };

  const toggleRequired = (objectId, e) => {  // Add e parameter here
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>  // Remove e from here
        obj.id === objectId 
          ? { ...obj, isRequired: e.target.checked }
          : obj
      )
    );
  };

  const addField = (objectId) => {
    const newField = {
      id: uuidv4(),
      key: "",
      type: "text",
      value: "",
      isRequired: false,
      radioValues: [],
      checkboxValues: [],
    };

    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === objectId
          ? { ...obj, fields: [...obj.fields, newField] }
          : obj
      )
    );
  };

  const updateField = (objectId, fieldId, key, value) => {
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === objectId
          ? {
              ...obj,
              fields: obj.fields.map((field) =>
                field.id === fieldId
                  ? {
                      ...field,
                      [key]: value,
                      ...(key === "type" && {
                        radioValues: value === "radio" ? [] : field.radioValues,
                        checkboxValues: value === "checkbox" ? [] : field.checkboxValues,
                        value: "",  // Reset value when type changes
                      }),
                    }
                  : field
              ),
            }
          : obj
      )
    );
  };

  const deleteObject = (objectId) => {
    // Prevent deleting the last object
    if (objects.length === 1) {
      toast.warning("Cannot delete the last object. At least one object is required.");
      return;
    }
    setObjects((prevObjects) => prevObjects.filter((obj) => obj.id !== objectId));
  };

  const removeField = (objectId, fieldId) => {
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === objectId
          ? {
              ...obj,
              fields: obj.fields.filter((field) => field.id !== fieldId),
            }
          : obj
      )
    );
  };

  const addRadioValue = (objectId, fieldId) => {
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === objectId
          ? {
              ...obj,
              fields: obj.fields.map((field) =>
                field.id === fieldId
                  ? { ...field, radioValues: [...field.radioValues, ""] }
                  : field
              ),
            }
          : obj
      )
    );
  };

  const updateRadioValue = (objectId, fieldId, index, value) => {
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === objectId
          ? {
              ...obj,
              fields: obj.fields.map((field) =>
                field.id === fieldId
                  ? {
                      ...field,
                      radioValues: field.radioValues.map((val, i) =>
                        i === index ? value : val
                      ),
                    }
                  : field
              ),
            }
          : obj
      )
    );
  };

  const removeRadioValue = (objectId, fieldId, index) => {
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === objectId
          ? {
              ...obj,
              fields: obj.fields.map((field) =>
                field.id === fieldId
                  ? {
                      ...field,
                      radioValues: field.radioValues.filter((_, i) => i !== index),
                    }
                  : field
              ),
            }
          : obj
      )
    );
  };

  const addCheckboxValue = (objectId, fieldId) => {
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === objectId
          ? {
              ...obj,
              fields: obj.fields.map((field) =>
                field.id === fieldId
                  ? { ...field, checkboxValues: [...field.checkboxValues, ""] }
                  : field
              ),
            }
          : obj
      )
    );
  };

  const updateCheckboxValue = (objectId, fieldId, index, value) => {
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === objectId
          ? {
              ...obj,
              fields: obj.fields.map((field) =>
                field.id === fieldId
                  ? {
                      ...field,
                      checkboxValues: field.checkboxValues.map((val, i) =>
                        i === index ? value : val
                      ),
                    }
                  : field
              ),
            }
          : obj
      )
    );
  };

  const removeCheckboxValue = (objectId, fieldId, index) => {
    setObjects((prevObjects) =>
      prevObjects.map((obj) =>
        obj.id === objectId
          ? {
              ...obj,
              fields: obj.fields.map((field) =>
                field.id === fieldId
                  ? {
                      ...field,
                      checkboxValues: field.checkboxValues.filter(
                        (_, i) => i !== index
                      ),
                    }
                  : field
              ),
            }
          : obj
      )
    );
  };

  const saveAllObjects = async () => {
    try {
      // Validation
      const hasEmptyFields = objects.some(
        obj => !obj.objectName || obj.fields.some(field => !field.key)
      );

      if (hasEmptyFields) {
        toast.error("Please fill in all object names and field keys");
        return;
      }

      const savePromises = objects.map((object) => {
        const docId = uuidv4();
        return setDoc(doc(db, "CustomObjects", docId), {
          ...object,
          id: docId,
        });
      });

      await Promise.all(savePromises);
      toast.success("Fields Created Successfully!");
      navigate("/view-fields");

      setObjects([
        {
          id: uuidv4(),
          objectName: "",
          isRequired: false,
          fields: [],
        },
      ]);
    } catch (error) {
      console.error("Error saving objects:", error);
      toast.error("Failed to save objects. Please try again.");
    }
  };

  return (
    <div className="p-4">
      <h1 className="font-bold text-xl mb-4">Create Custom Objects</h1>

      {objects.map((object) => (
        <div key={object.id} className="p-4 border rounded-md shadow-md mt-4">
               <div className="flex items-center justify-between gap-2 mb-4">
            <div className="flex-1">
              <input
                type="text"
                placeholder="Object Name"
                value={object.objectName}
                onChange={(e) => updateObjectName(object.id, e.target.value)}
                className="w-full px-2 py-1 rounded-md border border-neutral-300"
                required
              />
            </div>
            <button
              onClick={() => deleteObject(object.id)}
              className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 flex items-center gap-1"
              title="Delete Object"
            >
              <span aria-hidden="true">🗑️</span>
              Delete
            </button>
          </div>

          <div className="flex items-center gap-2 mb-4">
            <input
              type="checkbox"
              checked={object.isRequired}
              onChange={(e) => toggleRequired(object.id, e)}  // Pass the event object
              className="w-4 h-4"
              id={`required-${object.id}`}
            />
            <label
              htmlFor={`required-${object.id}`}
              className="text-sm whitespace-nowrap"
            >
              Required Object
            </label>
          </div>

          <button
            onClick={() => addField(object.id)}
            className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600 mb-4"
          >
            Add Field
          </button>

          {object.fields.map((field) => (
            <div key={field.id} className="mt-2">
              <div className="flex items-center gap-2 p-2 border rounded-md bg-gray-50">
                <input
                  type="text"
                  placeholder="Field Key"
                  value={field.key}
                  onChange={(e) =>
                    updateField(object.id, field.id, "key", e.target.value)
                  }
                  className="w-3/5 px-2 py-1 rounded-md border border-neutral-300"
                  required
                />

                <select
                  value={field.type}
                  onChange={(e) =>
                    updateField(object.id, field.id, "type", e.target.value)
                  }
                  className="w-1/4 px-1 py-1 border rounded-md"
                >
                  <option value="text">Text</option>
                  <option value="number">Number</option>
                  <option value="date">Date</option>
                  <option value="radio">Radio</option>
                  <option value="checkbox">Checkbox</option>
                </select>

                <button
                  onClick={() => removeField(object.id, field.id)}
                  className="text-red-500 hover:text-red-700"
                  aria-label="Remove field"
                >
                  <span aria-hidden="true">🗑️</span>
                </button>
              </div>

              {field.type !== "radio" && field.type !== "checkbox" && (
                <div className="flex items-center gap-2 mt-2">
                  <input
                    type={field.type}
                    placeholder="Default Value"
                    value={field.value}
                    onChange={(e) =>
                      updateField(object.id, field.id, "value", e.target.value)
                    }
                    className="w-full px-2 py-1 rounded-md border border-neutral-300"
                  />
                </div>
              )}

              {field.type === "radio" && (
                <div className="mt-2">
                  <button
                    onClick={() => addRadioValue(object.id, field.id)}
                    className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600"
                  >
                    Add Radio Option
                  </button>
                  {field.radioValues.map((radioValue, index) => (
                    <div key={index} className="flex items-center gap-2 mt-2">
                      <input
                        type="text"
                        placeholder={`Option ${index + 1}`}
                        value={radioValue}
                        onChange={(e) =>
                          updateRadioValue(
                            object.id,
                            field.id,
                            index,
                            e.target.value
                          )
                        }
                        className="w-full px-2 py-1 rounded-md border border-neutral-300"
                      />
                      <button
                        onClick={() => removeRadioValue(object.id, field.id, index)}
                        className="text-red-500 hover:text-red-700"
                        aria-label="Remove radio option"
                      >
                        <span aria-hidden="true">🗑️</span>
                      </button>
                    </div>
                  ))}
                </div>
              )}

              {field.type === "checkbox" && (
                <div className="mt-2">
                  <button
                    onClick={() => addCheckboxValue(object.id, field.id)}
                    className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600"
                  >
                    Add Checkbox Option
                  </button>
                  {field.checkboxValues.map((checkboxValue, index) => (
                    <div key={index} className="flex items-center gap-2 mt-2">
                      <input
                        type="text"
                        placeholder={`Option ${index + 1}`}
                        value={checkboxValue}
                        onChange={(e) =>
                          updateCheckboxValue(
                            object.id,
                            field.id,
                            index,
                            e.target.value
                          )
                        }
                        className="w-full px-2 py-1 rounded-md border border-neutral-300"
                      />
                      <button
                        onClick={() =>
                          removeCheckboxValue(object.id, field.id, index)
                        }
                        className="text-red-500 hover:text-red-700"
                        aria-label="Remove checkbox option"
                      >
                        <span aria-hidden="true">🗑️</span>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}

      <div className="flex justify-between mt-6">
        <button
          onClick={addObjectHandler}
          className="bg-secondaryColor text-white px-4 py-2 rounded-md"
        >
          Add Object
        </button>

        {objects.length > 0 && (
          <button
            onClick={saveAllObjects}
            className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6"
          >
            Save All Objects
          </button>
        )}
      </div>
    </div>
  );
};

export default AddCustomObjects;