import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const ViewSingleLoanData = () => {
  const { id } = useParams(); // Get the ID from route parameters
  const [loanProgram, setLoanProgram] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchLoanProgram = async () => {
    try {
      const docRef = doc(db, "loanPrograms", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setLoanProgram({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.error("No such document!");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching loan program:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoanProgram();
  }, [id]);

  console.log("loanProgram", loanProgram);

  // Utility function to safely retrieve values and default to 0
  const getValue = (value) =>
    value !== undefined && value !== null && value !== "" ? value : 0;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!loanProgram) {
    return <div>Loan Program not found</div>;
  }

  return (
    <div className="overflow-x-auto p-6">
      <h1 className="font-bold text-xl text-secondaryColor">
        Loan Program Details
      </h1>

      <div className="border border-gray-400 rounded-2xl p-6">
        <h1 className="text-2xl text-secondaryColor">Basic Details</h1>
        <div className="flex justify-between text-sm">
          <div>
            <strong>Loan Program Name:</strong>{" "}
            {loanProgram.loan_program_name || "N/A"}
          </div>

          <div>
            <strong>Bank:</strong> {loanProgram?.bank?.bankName || "N/A"}
          </div>

          <div>
            <strong>Program Category:</strong>{" "}
            {loanProgram.program_category || "N/A"}
          </div>
        </div>
      </div>

      <div className="border border-gray-400 rounded-2xl p-6 mt-6">
        <h1 className="text-2xl text-secondaryColor">Financial Details</h1>

        <h1 className="text-primaryColor my-2">Financing Limits:</h1>
        <div className="flex justify-between text-sm">
          <div>
            <strong className="">Minimum Financing:</strong>{" "}
            {getValue(
              loanProgram.financial_details?.financing_limits?.minimum_financing
            )}
          </div>

          <div>
            <strong>Maximum Financing:</strong>{" "}
            {getValue(
              loanProgram.financial_details?.financing_limits?.maximum_financing
            )}
          </div>

          <div>
            <strong>Financing Percentage:</strong>{" "}
            {getValue(
              loanProgram.financial_details?.financing_limits
                ?.financing_percentage
            )}
          </div>
        </div>

        <h1 className=" text-primaryColor my-2">Fees and Charges:</h1>
        <div className="flex justify-between text-sm">
          <div>
            <strong>Application Fee:</strong>{" "}
            {getValue(
              loanProgram.financial_details?.fees_and_charges?.application_fee
            )}
          </div>

          <div>
            <strong>Processing Fee:</strong>{" "}
            {getValue(
              loanProgram.financial_details?.fees_and_charges?.processing_fee
            )}
          </div>

          <div>
            <strong>Early Settlement Fee:</strong>{" "}
            {getValue(
              loanProgram.financial_details?.fees_and_charges
                ?.early_settlement_fee
            )}
          </div>
        </div>

        <h1 className=" text-primaryColor my-2">Interest Details:</h1>
        <div className="flex justify-between text-sm">
          <div>
            <strong>Fixed Rate:</strong>{" "}
            {getValue(
              loanProgram.financial_details?.interest_details?.fixed_rate
            )}
          </div>

          <div>
            <strong>Murabaha Ratio:</strong>{" "}
            {getValue(
              loanProgram.financial_details?.interest_details?.murabaha_ratio
            )}
          </div>

          <div>
            <strong>Variable Rate:</strong>{" "}
            {getValue(
              loanProgram.financial_details?.interest_details?.variable_rate
            )}
          </div>
        </div>
      </div>

      <div className="border border-gray-400 rounded-2xl p-6 mt-6">
        <h1 className="text-2xl text-secondaryColor">Loan Term Details</h1>
        <div className="flex justify-between text-sm">
          <div>
            <strong>Minimum Years:</strong>{" "}
            {getValue(loanProgram.loan_term_details?.minimum_years)}
          </div>

          <div>
            <strong>Maximum Years:</strong>{" "}
            {getValue(loanProgram.loan_term_details?.maximum_years)}
          </div>
        </div>

        <div className="flex justify-between text-sm">
          <div>
            <strong>Starting Date:</strong>{" "}
            {getValue(loanProgram.loan_term_details?.starting_date)}
          </div>

          <div>
            <strong>Ending Date:</strong>{" "}
            {getValue(loanProgram.loan_term_details?.ending_date)}
          </div>
        </div>
      </div>

      <div className="border border-gray-400 rounded-2xl p-6 mt-6">
        <h1 className="text-2xl text-secondaryColor">
          Program Specific Details
        </h1>

        {Object.entries(loanProgram.program_specific_details || {}).map(
          ([objName, details]) => (
            <div key={objName} className="my-2">
              
              <ul>
                <li className="font-semibold text-primaryColor list-disc">{objName}</li>
              </ul>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ViewSingleLoanData;
