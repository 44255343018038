import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import CustomDataTable from "../../components/CustomDataTable";

const BankEmployee = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("permissions")) || {};
  const { BankEmployeeAdd: canAdd, BankEmployeeUpdate: canEdit, BankEmployeeDelete: canDelete } = permissions;

  useEffect(() => {
    fetchBankEmployees();
  }, []);

  const fetchBankEmployees = async () => {
    setLoading(true);
    setError(null);
    try {
      const banksCollectionRef = collection(db, "Banks");
      const banksSnapshot = await getDocs(banksCollectionRef);

      const employeePromises = banksSnapshot.docs.map(async (bankDoc) => {
        const bankId = bankDoc.id;
        const bankData = bankDoc.data();
        const bankName = bankData.name; // Assuming the bank name is stored in a field called 'bankName'

        const bankEmployeeCollectionRef = collection(
          db,
          "Banks",
          bankId,
          "BankEmployee"
        );
        const employeeSnapshot = await getDocs(bankEmployeeCollectionRef);
        return employeeSnapshot.docs.map((employeeDoc) => ({
          id: employeeDoc.id,
          bankId,
          bankName, // Include the bank name in the employee data
          ...employeeDoc.data(),
        }));
      });

      const allEmployees = (await Promise.all(employeePromises)).flat();
      setEmployees(allEmployees);

      // Log the banks data to the console
      console.log("Banks Data:", banksSnapshot.docs.map(doc => doc.data()));
    } catch (error) {
      console.error("Error fetching employees:", error.message);
      setError("Failed to load bank employees. Please try again later.");
      toast.error("Error: Could not fetch employees.");
      logUserActivity("User attempted to view Bank Employees but failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (employeeId, bankId) => {
    try {
      await deleteDoc(doc(db, "Banks", bankId, "BankEmployee", employeeId));
      toast.success("Employee deleted successfully!");
      logUserActivity("User deleted a Bank Employee.");
      fetchBankEmployees(); // Refresh data after deletion
    } catch (error) {
      console.error("Error deleting employee:", error.message);
      toast.error("Error: Failed to delete employee.");
    }
  };

  const columns = [
    {
      field: "serialNo",
      headerName: "الرقم التسلسلي",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "employeeId",
      headerName: "معرف الموظف",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "bankName", // New column for bank name
      headerName: "اسم البنك",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "bankEmployeeName",
      headerName: "اسم الموظف",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "employeeDepartment",
      headerName: "قسم",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "contactNumber",
      headerName: "اتصال",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "bankEmployeeEmail",
      headerName: "بريد إلكتروني",
      headerClassName: "header",
      flex: 1,
    
    },
    ...(canEdit || canDelete
      ? [
          {
            field: "action",
            headerName: "الإجراءات",
            flex: 1,
            headerClassName: "header",
           
            renderCell: (params) => {
              const employeeId = params.row.id;
              return (
                <div className="flex items-center gap-2 h-full">
                  {canEdit && (
                    <Link
                      to={`/update-bank-employee/${params.row.bankId}/${params.row.id}`}
                      className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
                    >
                      <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
                    </Link>
                  )}
                  {canDelete && (
                    <div
                      onClick={() => handleDelete(employeeId, params.row.bankId)}
                      className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
                    >
                      <FaTrash className="text-white h-4 w-4" />
                    </div>
                  )}
                </div>
              );
            },
          },
        ]
      : []),
  ];

  const rows = employees.map((employee, index) => ({
    ...employee,
    serialNo: index + 1,
  }));

  return (
    <div  style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl text-secondaryColor">
          جميع موظفي البنك
        </h1>
        {canAdd && (
          <Link
            to="/add-bank-employee"
            className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer"
          >
            إضافة موظف البنك
          </Link>
        )}
      </div>

      <CustomDataTable
        rows={rows}
        columns={columns}
        loading={loading}
        error={error}
        noDataMessage="لم يتم العثور على بيانات"
        loadingMessage="جاري التحميل..."
      />
    </div>
  );
};

export default BankEmployee;