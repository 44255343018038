import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import SearchableDropdown from "../../components/fields/SearchableDropdown";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";

const UpdateLoanProgram = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [programTypes, setProgramType] = useState([]);
  const [selectedProgramType, setSelectedProgramType] = useState("");
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [programData, setProgramData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [objects, setObjects] = useState([]);
  const [selectedObjectIds, setSelectedObjectIds] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch loan program details
  useEffect(() => {
    const fetchProgramDetails = async () => {
      try {
        const docRef = doc(db, "loanPrograms", id);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setProgramData(data);

          // Set pre-filled values for dropdowns
          setSelectedBank(data.bank || null);
          setSelectedProgramType(data.program_type || null);

          if (data.program_specific_details) {
            setFormData({
              ...data,
              program_category: data.program_category || null,
              program_specific_details: data.program_specific_details,
            });
          }
        } else {
          setError("Loan program not found.");
        }
      } catch (err) {
        console.error("Error fetching loan program:", err);
        setError("Failed to fetch loan program details.");
      } finally {
        setLoading(false);
      }
    };

    fetchProgramDetails();
  }, [id, db]);

  const navigate = useNavigate();

  const { control, setValue } = useForm();

  const renameKeysToRemoveQuestionMark = (obj) => {
    const cleanObj = {};

    Object.keys(obj).forEach((key) => {
      const newKey = key.replace("?", "");
      if (typeof obj[key] === "object" && obj[key] !== null) {
        cleanObj[newKey] = Array.isArray(obj[key])
          ? obj[key].map((item) =>
              typeof item === "object"
                ? renameKeysToRemoveQuestionMark(item)
                : item
            )
          : renameKeysToRemoveQuestionMark(obj[key]);
      } else {
        cleanObj[newKey] = obj[key];
      }
    });

    return cleanObj;
  };

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const banksCollectionRef = collection(db, "Banks");
        const bankSnapshot = await getDocs(banksCollectionRef);
        const banksData = bankSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBanks(banksData);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };

    fetchBanks();
  }, []);

  useEffect(() => {
    setValue("baseFields.selectedBank", selectedBank);
  }, [selectedBank, setValue]);

  const bankOptions = banks.map((bank) => ({
    value: bank.name,
    label: bank.name,
  }));

  useEffect(() => {
    const fetchProgramType = async () => {
      try {
        const programTypesCollectionRef = collection(db, "ProgramType");
        const programTypeSnapshot = await getDocs(programTypesCollectionRef);
        const programTypesData = programTypeSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProgramType(programTypesData);
      } catch (error) {
        console.error("Error fetching program types:", error);
      }
    };

    fetchProgramType();
  }, []);

  useEffect(() => {
    setValue("baseFields.selectedProgramType", selectedProgramType); // Set program type in form state
  }, [selectedProgramType, setValue]);

  const programOptions = programTypes.map((program) => ({
    value: program.id,
    label: program.programtype,
  }));

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesCollectionRef = collection(db, "Categories");
        const categorySnapshot = await getDocs(categoriesCollectionRef);
        const categoriesData = categorySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedProgramType) {
      console.log("Selected Program Type:", selectedProgramType);

      const filtered = categories.filter((category) => {
        console.log("Category ProgramTypeId:", category.programTypeId); // Log the category programTypeId to check the value
        return category.programTypeId === selectedProgramType; // Adjust this based on your actual field
      });

      console.log("Filtered Categories:", filtered);
      setFilteredCategories(filtered);
    }
  }, [selectedProgramType, categories]);


  const handleDeleteObject = (objectName) => {
    setFormData((prev) => {
      const updatedDetails = { ...prev.program_specific_details };
      delete updatedDetails[objectName];
      return {
        ...prev,
        program_specific_details: updatedDetails,
      };
    });
  };

  
  
  const handleObjectSelection = () => {
    // Filter and initialize fields for the selected objects
    const selectedData = objects
      .filter((object) => selectedObjectIds.includes(object.id))
      .reduce((acc, object) => {
        // Use objectName as the key instead of object.id
        const existingData =
          formData.program_specific_details?.[object.objectName] || {};
  
        // Initialize fields, preserving existing values if available
        acc[object.objectName] = object.fields.reduce((fieldAcc, field) => {
          if (field.type === "checkbox") {
            fieldAcc[field.key] = existingData[field.key] || [];
          } else {
            fieldAcc[field.key] = existingData[field.key] || "";
          }
          return fieldAcc;
        }, {});
  
        return acc;
      }, {});
  
    // Update formData
    setFormData((prevData) => ({
      ...prevData,
      program_specific_details: {
        ...prevData.program_specific_details,
        ...selectedData,
      },
    }));
  
    setIsModalOpen(false);
  };
  
  // Modify your field change handlers to use objectName instead of objectId
  const handleFieldChange = (objectName, fieldKey, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      program_specific_details: {
        ...prevFormData.program_specific_details,
        [objectName]: {
          ...(prevFormData.program_specific_details?.[objectName] || {}),
          [fieldKey]: value,
        },
      },
    }));
  };
  
  const handleRadioChange = (objectName, fieldKey, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      program_specific_details: {
        ...prevFormData.program_specific_details,
        [objectName]: {
          ...(prevFormData.program_specific_details?.[objectName] || {}),
          [fieldKey]: value,
        },
      },
    }));
  };
  
  const handleFieldCheckboxChange = (objectName, fieldKey, value) => {
    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        program_specific_details: {
          ...prevFormData.program_specific_details,
          [objectName]: {
            ...(prevFormData.program_specific_details?.[objectName] || {}),
          },
        },
      };
  
      const currentValues =
        newFormData.program_specific_details[objectName][fieldKey] || [];
  
      newFormData.program_specific_details[objectName][fieldKey] =
        currentValues.includes(value)
          ? currentValues.filter((v) => v !== value)
          : [...currentValues, value];
  
      return newFormData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Debugging: Log formData and formData.id
      console.log("Form Data:", formData);
      console.log("Document ID:", id);

      const selectedBankObj = banks.find((bank) => bank.id === selectedBank);
  
      // Prepare cleaned data
      const cleanedFormData = {
        loan_program_name: formData.loan_program_name,
        program_category: formData.program_category,
        financial_details: {
          financing_limits: {
            minimum_financing:
              formData?.financial_details?.financing_limits
                ?.minimum_financing || "",
            maximum_financing:
              formData?.financial_details?.financing_limits
                ?.maximum_financing || "",
            financing_percentage:
              formData?.financial_details?.financing_limits
                ?.financing_percentage || "",
          },
          interest_details: {
            murabaha_ratio:
              formData?.financial_details?.interest_details?.murabaha_ratio ||
              "",
            fixed_rate:
              formData?.financial_details?.interest_details?.fixed_rate || "",
            variable_rate:
              formData?.financial_details?.interest_details?.variable_rate ||
              "",
          },
          fees_and_charges: {
            application_fee:
              formData?.financial_details?.fees_and_charges?.application_fee ||
              "",
            processing_fee:
              formData?.financial_details?.fees_and_charges?.processing_fee ||
              "",
            early_settlement_fee:
              formData?.financial_details?.fees_and_charges
                ?.early_settlement_fee || "",
          },
        },
        loan_term_details: {
          minimum_years: formData?.loan_term_details?.minimum_years || "",
          maximum_years: formData?.loan_term_details?.maximum_years || "",
          starting_date: formData?.loan_term_details?.starting_date || "",
          ending_date: formData?.loan_term_details?.ending_date || "",
          repayment_options:
            formData?.loan_term_details?.repayment_options || [],
        },
        bank: {
          bankId: selectedBank, // Include bankId
          bankName: selectedBankObj?.name || "", // Include bankName
        },
        program_type: selectedProgramType,
        program_specific_details: Object.entries(
          formData.program_specific_details || {}
        ).reduce((acc, [objectId, fields]) => {
          const objectDef = objects.find((obj) => obj.id === objectId);
          if (!objectDef) return acc;
  
          const objectName = objectDef.objectName;
          acc[objectName] = {};
  
          objectDef.fields.forEach((field) => {
            const fieldValue = fields?.[field.key];
            acc[objectName][field.key] =
              field.type === "checkbox"
                ? Array.isArray(fieldValue)
                  ? fieldValue
                  : []
                : fieldValue || "";
          });
  
          return acc;
        }, {}),
      };
  
      // Debugging: Log cleaned data
      console.log("Cleaned Data:", formData);
  
      // Remove undefined or null values
      const cleanedData = JSON.parse(JSON.stringify(formData));
  
      // Firebase update
      const docRef = doc(db, "loanPrograms", id);
      await updateDoc(docRef, cleanedData);
  
      // Success: Log and reset form
      console.log("Document updated successfully:", id);
      setFormData({});
      setSelectedBank("");
      setSelectedProgramType("");
      setSelectedObjectIds([]);
      toast.success("Loan program successfully updated!");
      navigate("/view-loan");
    } catch (error) {
      console.error("Error updating document:", error);
  
      // Show error toast or user feedback
      toast.error("Failed to update the loan program. Please try again.");
    }
  };
  

  // Fetch objects from Firestore
  useEffect(() => {
    const fetchObjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "CustomObjects"));
        const fetchedObjects = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setObjects(fetchedObjects);
      } catch (error) {
        console.error("Error fetching objects:", error);
      }
    };

    fetchObjects();
  }, []);



  const handleObjectSelectionToggle = (objectId) => {
    setSelectedObjectIds((prevSelected) =>
      prevSelected.includes(objectId)
        ? prevSelected.filter((id) => id !== objectId)
        : [...prevSelected, objectId]
    );
  };




  const handleChange = (fieldPath, value) => {
    setFormData((prevFormData) => {
      const updatedData = { ...prevFormData };

      const keys = fieldPath.split(".");
      let temp = updatedData;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          temp[key] = value;
        } else {
          if (!temp[key]) temp[key] = {};
          temp = temp[key];
        }
      });

      console.log("Updated formData:", updatedData); // Debugging state update
      return updatedData; // Return updated state
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="shadow-lg p-10 rounded-2xl">
        <h2 className="text-2xl">General Information</h2>

        <div className="flex justify-between gap-6 my-6">
          <div className="w-full">
            <input
              type="text"
              placeholder="Program Name"
              value={formData?.loan_program_name || ""} // Make sure this binds correctly to the state
              onChange={(e) =>
                handleChange("loan_program_name", e.target.value)
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
            />
          </div>

          <div className="w-full">
            <Controller
              name="bank"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={bankOptions}
                  selectedValue={selectedBank} // Show the pre-selected value
                  setSelectedValue={(value) => {
                    setSelectedBank(value);
                    field.onChange(value); // Sync with react-hook-form
                  }}
                  placeholder="Select a Bank"
                />
              )}
            />
          </div>
        </div>

        <div className="flex justify-center gap-6 mb-6">
          {/* Program Type Dropdown */}
          <div className="w-full">
            <Controller
              name="programtype"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={programOptions}
                  selectedValue={selectedProgramType}
                  setSelectedValue={(value) => {
                    setSelectedProgramType(value);
                    field.onChange(value); // Ensure the value is passed to react-hook-form
                  }}
                  placeholder="Select a programType"
                />
              )}
            />
          </div>

          {/* Program Category Dropdown (SearchableDropdown) */}
          <div className="w-full">
            <Controller
              name="program_category"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={filteredCategories.map((category) => ({
                    value: category.categoryName, // You can adjust this field if necessary
                    label: category.categoryName, // Adjust according to your category object structure
                  }))}
                  selectedValue={formData?.program_category}
                  setSelectedValue={(value) => {
                    handleChange("program_category", value);
                    field.onChange(value); // Ensure the value is passed to react-hook-form
                  }}
                  placeholder="Select a program category"
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="shadow-lg p-10 rounded-2xl my-10">
        <h2 className="text-2xl pb-6">Financial Details</h2>
        <h3>Financing Limits</h3>

        <div className="flex justify-between gap-6 mb-6">
          <div className="w-full">
            <input
              type="number"
              placeholder="Minimum Financing"
              value={
                programData?.financial_details?.financing_limits
                  ?.minimum_financing ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.financing_limits.minimum_financing",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="Maximum Financing"
              value={
                programData?.financial_details?.financing_limits
                  ?.maximum_financing ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.financing_limits.maximum_financing",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="Financing Percentage"
              value={
                programData?.financial_details?.financing_limits
                  ?.financing_percentage ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.financing_limits.financing_percentage",
                  e.target.value
                )
              }
            />
          </div>
        </div>

        {/* Financial Details Section */}
        <h3>Interest Details</h3>
        <div className="flex justify-between gap-6 mb-6">
          <div className="w-full">
            <input
              type="number"
              placeholder="Murabaha Ratio"
              value={
                programData?.financial_details?.interest_details
                  ?.murabaha_ratio ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.interest_details.murabaha_ratio",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="Fixed Rate"
              value={
                programData?.financial_details?.interest_details?.fixed_rate ??
                ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.interest_details.fixed_rate",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="Variable Rate"
              value={
                programData?.financial_details?.interest_details
                  ?.variable_rate ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.interest_details.variable_rate",
                  e.target.value
                )
              }
            />
          </div>
        </div>
        <h3>Fees and Charges</h3>
        <div className="flex justify-between gap-6">
          <div className="w-full">
            <input
              type="number"
              placeholder="Application Fee"
              value={
                programData?.financial_details?.fees_and_charges
                  ?.application_fee ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.fees_and_charges.application_fee",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="Processing Fee"
              value={
                programData?.financial_details?.fees_and_charges
                  ?.processing_fee ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.fees_and_charges.processing_fee",
                  e.target.value
                )
              }
            />
          </div>

          <div className="w-full">
            <input
              type="number"
              placeholder="Early Settlement Fee"
              value={
                programData?.financial_details?.fees_and_charges
                  ?.early_settlement_fee ?? ""
              }
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange(
                  "financial_details.fees_and_charges.early_settlement_fee",
                  e.target.value
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="shadow-lg p-10 rounded-2xl">
        {/* Loan Term Details Section */}
        <h2 className="text-2xl pb-6">Loan Term Details</h2>
        <div className="flex justify-between gap-6">
          {/* Minimum Years */}
          <div className="w-full">
            <input
              type="number"
              placeholder="Minimum Years"
              value={programData?.loan_term_details?.minimum_years ?? ""}
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange("loan_term_details.minimum_years", e.target.value)
              }
            />
          </div>

          {/* Maximum Years */}
          <div className="w-full">
            <input
              type="number"
              placeholder="Maximum Years"
              value={programData?.loan_term_details?.maximum_years ?? ""}
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange("loan_term_details.maximum_years", e.target.value)
              }
            />
          </div>
        </div>

        <div className="flex justify-between gap-6 mt-6">
          {/* Starting Date */}
          <div className="w-full">
            <label htmlFor="">Starting Date</label>
            <input
              type="date"
              placeholder="Starting Date"
              value={programData?.loan_term_details?.starting_date ?? ""}
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange("loan_term_details.starting_date", e.target.value)
              }
            />
          </div>

          {/* Ending Date */}
          <div className="w-full">
            <label htmlFor="">Ending Date</label>
            <input
              type="date"
              placeholder="Ending Date"
              value={programData?.loan_term_details?.ending_date ?? ""}
              className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
              onChange={(e) =>
                handleChange("loan_term_details.ending_date", e.target.value)
              }
            />
          </div>
        </div>
      </div>

      <div className="shadow-lg p-10 rounded-2xl">
  <div className="flex justify-between items-center">
    <h2 className="text-3xl">Program Specific Details</h2>
    <button
      type="button"
      onClick={() => setIsModalOpen(true)}
      className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 py-2"
    >
      Add Custom Object
    </button>
  </div>
  {formData.program_specific_details &&
    Object.keys(formData.program_specific_details).length > 0 && (
      <div>
        {Object.entries(formData.program_specific_details).map(
          ([objectName, objectFields]) => {
            // Find the object definition by its name
            const objectDef = objects.find(
              (obj) => obj.objectName === objectName
            );

            if (!objectDef) return null;

            return (
              <div key={objectName} className="mb-6">
                <div className="flex justify-between items-center">
                  <h3 className="text-xl mb-3">{objectDef.objectName}</h3>
                  <button
                    type="button"
                    onClick={() => handleDeleteObject(objectName)}
                    className="text-red-500 hover:text-red-700"
                    aria-label={`Delete ${objectDef.objectName}`}
                  >
                    🗑️ {/* Replace with an icon from your preferred icon library */}
                  </button>
                </div>
                {objectDef.fields.map((field) => {
                  const fieldValue = objectFields[field.key];
                  const uniqueKey = `${objectName}-${field.key}`;

                  return (
                    <div key={uniqueKey} className="mb-4">
                      <label className="block mb-2 capitalize">
                        {field.key}
                      </label>

                      {/* Radio buttons */}
                      {field.type === "radio" &&
                        field.radioValues?.length > 0 && (
                          <div>
                            {field.radioValues.map((value, idx) => (
                              <div key={idx} className="inline-block mr-4">
                                <input
                                  type="radio"
                                  id={`${objectName}-${field.key}-${value}`}
                                  name={`${objectName}-${field.key}`}
                                  value={value}
                                  checked={fieldValue === value}
                                  onChange={() =>
                                    handleRadioChange(
                                      objectName,
                                      field.key,
                                      value
                                    )
                                  }
                                  className="mr-2"
                                />
                                <label
                                  htmlFor={`${objectName}-${field.key}-${value}`}
                                >
                                  {value}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}

                      {/* Checkbox */}
                      {field.type === "checkbox" &&
                        field.checkboxValues?.length > 0 && (
                          <div>
                            {field.checkboxValues.map((value, idx) => (
                              <div key={idx} className="inline-block mr-4">
                                <input
                                  type="checkbox"
                                  id={`${objectName}-${field.key}-${value}`}
                                  value={value}
                                  checked={(fieldValue || []).includes(value)}
                                  onChange={() =>
                                    handleFieldCheckboxChange(
                                      objectName,
                                      field.key,
                                      value
                                    )
                                  }
                                  className="mr-2"
                                />
                                <label
                                  htmlFor={`${objectName}-${field.key}-${value}`}
                                >
                                  {value}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}

                      {/* Text/Number fields */}
                      {(field.type === "text" || field.type === "number") && (
                        <input
                          type={field.type}
                          value={fieldValue || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              objectName,
                              field.key,
                              e.target.value
                            )
                          }
                          className="w-full p-2 border rounded"
                        />
                      )}

                      {/* Date field */}
                      {field.type === "date" && (
                        <input
                          type="date"
                          value={fieldValue || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              objectName,
                              field.key,
                              e.target.value
                            )
                          }
                          className="w-full p-2 border rounded"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          }
        )}
      </div>
    )}

  <Modal
    isOpen={isModalOpen}
    onRequestClose={() => setIsModalOpen(false)}
    contentLabel="Add Custom Object"
    className="bg-white p-6 shadow-lg rounded-md w-1/2 mx-auto"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
  >
    <h2 className="text-xl mb-4">Add Custom Object</h2>
    <div className="space-y-4">
      {objects.map((object) => (
        <div key={object.id} className="flex items-center gap-2">
          <input
            type="checkbox"
            id={`object-select-${object.id}`}
            checked={selectedObjectIds.includes(object.id)}
            onChange={() => handleObjectSelectionToggle(object.id)}
          />
          <label htmlFor={`object-select-${object.id}`}>
            {object.objectName}
          </label>
        </div>
      ))}
    </div>
    <div className="mt-4 flex justify-end">
      <button
        onClick={handleObjectSelection}
        className="bg-primaryColor text-white px-4 py-2 rounded-lg"
      >
        Save
      </button>
      <button
        onClick={() => setIsModalOpen(false)}
        className="ml-2 bg-gray-300 px-4 py-2 rounded-lg"
      >
        Cancel
      </button>
    </div>
  </Modal>
</div>


      {/* Add similar sections for financial requirements, financial details, loan terms, and program-specific details */}

      <button
        type="submit"
        className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
      >
        Submit
      </button>
    </form>
  );
};

export default UpdateLoanProgram;
