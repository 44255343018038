import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { arSD } from "@mui/x-data-grid/locales";

const CustomDataTable = ({ 
  rows, 
  columns, 
  loading, 
  error, 
  noDataMessage = "لم يتم العثور على بيانات",
  loadingMessage = "جاري التحميل...",
}) => {
  if (loading) {
    return <div className="loader-container">{loadingMessage}</div>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (rows.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-[400px]">
        <p className="text-gray-500">{noDataMessage}</p>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", height: "400px", overflow: "hidden" }}>
      <div style={{ direction: "rtl", height: "100%" }}>
      <DataGrid
  rows={rows}
  columns={columns}
  getRowId={(row) => row.id}
  localeText={arSD.components.MuiDataGrid.defaultProps.localeText}
  
  componentsProps={{
    pagination: {
      labelRowsPerPage: "صفوف في الصفحة",
      labelDisplayedRows: ({ from, to, count }) =>
        `${from}-${to} من ${count}`,
    },
  }}
  sx={{
    "& .header": {
      backgroundColor: "#214783",
      color: "#FFFFFF",
      fontWeight: "bold",
      fontSize: "16px",
    },
    "& .MuiDataGrid-cell": {
      backgroundColor: "#f4f4f4",
      color: "#000",
      textAlign: "right",
    },
    "& .MuiDataGrid-columnHeaders": {
      direction: "rtl",
      textAlign: "right",
    },
    "& .MuiDataGrid-virtualScroller": {
      direction: "rtl",
      overflowX: "hidden",
    },
    "& .MuiDataGrid-footerContainer": {
      direction: "rtl",
    },
    "& .MuiDataGrid-menuIcon": {
      color: "#FFFFFF",
    },
    "& .MuiDataGrid-iconButtonContainer": {
      color: "#FFFFFF",
    },
    "& .MuiDataGrid-sortIcon": {
      color: "#FFFFFF",
    },
    "& .MuiTablePagination-actions": {
      "& .MuiIconButton-root": {
        transform: "rotate(180deg)",
      },
      color: "#214783",
    },
    "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--vertical": {
      left: "0px", // Move scrollbar to the left
      right: "unset", // Unset default right positioning
    },

 
  }}
/>

      </div>
    </div>
  );
};

export default CustomDataTable;
