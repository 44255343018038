import React from 'react';
import {
  Home as House,
  Briefcase,
  Banknote,
  Users,
  FileText,
  Building,
  FilePlus,
  FileCheck,
  User,
  UserCheck,
  Lock,
  Layers,
  Search,
  List,
} from 'lucide-react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import SidebarNavLink from './SidebarNavLink';

const Sidebar = ({ onToggleCollapse, isCollapsed, isRTL }) => {
  const role = JSON.parse(localStorage.getItem("user"))?.roleName;
  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];
  
  console.log("role", role);
  console.log("sidebar permission", permissions);

  const handleToggleCollapse = () => {
    onToggleCollapse(!isCollapsed);
  };

  return (
    <div
      className={`relative top-0 p-4 border-gray-600 dark:border-r shadow-md transition-width duration-500 ${
        isCollapsed ? 'w-20' : 'w-72'
      } ${isCollapsed ? 'min-h-0' : 'min-h-full'}`}
    >
      <div className="flex flex-col justify-between h-full">
        {/* Top Section */}
        <div>
          <nav className="space-y-2">
            {/* Each link has a unique icon */}
            <SidebarNavLink to="/" icon={House} label="لوحة القيادة" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/services" icon={Layers} label="خدمات" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/services-provider" icon={Users} label="مقدم الخدمات" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/program-type" icon={List} label="نوع البرنامج" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/view-categories" icon={FileText} label="عرض الفئات" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/view-fields" icon={FilePlus} label="عرض الحقول المخصصة" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/view-loan" icon={Briefcase} label="عرض برنامج القروض" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/banks" icon={Banknote} label="البنوك" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/bank-employee" icon={Users} label="موظف البنك" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/loan-application" icon={FileCheck} label="طلبات القروض" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/f-programs" icon={Search} label="بحث في برامج التمويل" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/employees" icon={Users} label="موظفين" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/clients" icon={User} label="العملاء" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/logs" icon={FileText} label="سجلات" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/broker-company" icon={Building} label="شركة وسيط" isCollapsed={isCollapsed} />
            
            
            {/* Only show this tab if the user role is "admin" */}
            {role === "admin" && (
              <SidebarNavLink to="/role" icon={UserCheck} label="دور" isCollapsed={isCollapsed} />
            )}
            
            <SidebarNavLink to="/permission" icon={Lock} label="Permissions" isCollapsed={isCollapsed} />
          </nav>
        </div>

        {/* Collapse Button */}
        <button
          onClick={handleToggleCollapse}
          className={`absolute bottom-4 ${
            isRTL ? 'left-[-10px]' : 'left-[-10px]'
          } text-white dark:text-gray-300 bg-secondaryColor rounded-full shadow-lg`}
        >
          {isCollapsed ?   <ChevronLeft size={24} /> : <ChevronRight size={24} />}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
