import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash, FaPencilAlt } from "react-icons/fa"; // Import the edit icon
import { Link } from "react-router-dom";
import { db } from "../../firebase"; // Adjust the import based on your project structure
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";
import CustomDataTable from "../../components/CustomDataTable";

const Employees = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  const canAdd = permissions.EmployeeAdd || false;
  const canEdit = permissions.EmployeeUpdate || false;
  const canDelete = permissions.EmployeeDelete || false;


  const baseColumns = [
    {
      field: "serialNo",
      headerName: "الرقم التسلسلي",
      flex: 1,
      headerClassName: "header",
      
    },
    {
      field: "name",
      headerName: "اسم الموظف",
      flex: 1,
      headerClassName: "header",
    
    },
    {
      field: "position",
      headerName: "مسمى وظيفي",
      flex: 1,
      headerClassName: "header",
   
    },
    {
      field: "email",
      headerName: "البريد الإلكتروني للموظف",
      flex: 1,
      headerClassName: "header",
     
    },
  ];

  

  // Conditionally add the "Action" column if permissions exist
  const actionColumn = {
    field: "action",
    headerName: "الإجراءات",
    flex: 1,
    headerClassName: "header",
   
    renderCell: (params) => (
      <div className="flex items-center gap-2 h-full"> {/* Ensure the parent has full height */}
        {canEdit && (
          <Link
            to={`/update-employee/${params.row.id}`}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
          </Link>
        )}
        {canDelete && (
          <div
            onClick={() => handleDelete(params.row.id)}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
          >
            <FaTrash className="text-white h-4 w-4" />
          </div>
        )}
      </div>
    ),
  };

  // Include the action column only if permissions exist
  const columns = canEdit || canDelete ? [...baseColumns, actionColumn] : baseColumns;

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Employees"));
        const employees = querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1,
          ...doc.data(),
        }));
        setHistory(employees);
        logUserActivity("User Viewed Employees");
      } catch (error) {
        console.error("Error fetching Employees: ", error);
        setError("Failed to load Employees.");
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Employee?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "Employees", id));
        setHistory(history.filter((item) => item.id !== id));
        logUserActivity("User Deleted Employee");
      } catch (error) {
        console.error("Error deleting Employees: ", error);
        setError("Failed to delete Employees.");
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-bold text-xl text-secondaryColor">جميع الموظفين</h1>
        </div>

        {canAdd && (
          <div>
            <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
              <Link to="/add-employee">إضافة موظف</Link>
            </button>
          </div>
        )}
      </div>

  
      <CustomDataTable
        rows={history}
        columns={columns}
        loading={loading}
        error={error}
        noDataMessage="لم يتم العثور على بيانات"
        loadingMessage="جاري التحميل..."
      />
    </div>
  );
};

export default Employees;


