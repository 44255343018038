import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";
import CustomDataTable from "../../components/CustomDataTable";


const ViewServices = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  const canAdd = permissions.ServiceAdd || false;
  const canEdit = permissions.ServiceUpdate || false;
  const canDelete = permissions.ServiceDelete || false;

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "هل أنت متأكد من حذف هذه الخدمة؟"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "Services", id));
        setData(data.filter((item) => item.id !== id));
        logUserActivity("User Deleted Services");
      } catch (error) {
        console.error("Error deleting data: ", error);
        setError("فشل في حذف البيانات");
      }
    }
  };

  const columns = [
    {
      field: "serialNo",
      headerName: "الرقم التسلسلي",
      flex: 1,
      headerClassName: "header",
     
    },
    {
      field: "servicename",
      headerName: "اسم الخدمات",
      flex: 1,
      headerClassName: "header",
    
    },
  ];

  if (canEdit || canDelete) {
    columns.push({
      field: "action",
      headerName: "الإجراءات",
      flex: 1,
      headerClassName: "header",
    
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full">
          {canEdit && (
            <div>
              <Link
                to={`/update-service/${params.row.id}`}
                className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
              >
                <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
              </Link>
            </div>
          )}
          {canDelete && (
            <div
              onClick={() => handleDelete(params.row.id)}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
            >
              <FaTrash className="text-white h-4 w-4" />
            </div>
          )}
        </div>
      ),
    });
  }

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Services"));
        const products = querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1,
          ...doc.data(),
        }));
        setData(products);
        logUserActivity("User Viewed Services");
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError("فشل في تحميل البيانات");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="w-full" dir="rtl">
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl text-secondaryColor">خدمات</h1>
        {canAdd && (
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
            <Link to="/add-services">أضف الخدمات</Link>
          </button>
        )}
      </div>

      <CustomDataTable
        rows={data}
        columns={columns}
        loading={loading}
        error={error}
        noDataMessage="لم يتم العثور على بيانات"
        loadingMessage="جاري التحميل..."
      />
    </div>
  );
};

export default ViewServices;