import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import { z } from "zod";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc, getDocs, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import SearchableDropdown from "../../components/fields/SearchableDropdown";

// Define schema for validation
const employeeSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Employee Name is required" })
    .max(30, { message: "Employee Name cannot exceed 30 characters" }),
  position: z
    .string()
    .min(1, { message: "Job Title is required" })
    .max(300, { message: "Job Title cannot exceed 300 characters" }),
  email: z
    .string()
    .email({ message: "Invalid email format" })
    .max(50, { message: "Employee Email cannot exceed 50 characters" }),
  nationalID: z
    .string()
    .regex(/^\d{14}$/, { message: "National ID must be 14 digits" })
    .optional(),
  phoneNumber: z
    .string()
    .regex(/^(\+?\d{1,3}[- ]?)?\d{10}$/, {
      message: "Phone Number must be a valid 10-digit number",
    })
    .optional(),
  employeeAddress: z
    .string()
    .min(5, {
      message:
        "Employee Address is required and must be at least 5 characters long",
    })
    .max(200, { message: "Employee Address cannot exceed 200 characters" })
    .optional(),
});

const UpdateEmployee = () => {
  const { id } = useParams(); // Get employee ID from URL
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(employeeSchema),
  });

  useEffect(() => {
    const fetchRolesAndEmployee = async () => {
      try {
        // Fetch roles
        const rolesCollection = collection(db, "roles");
        const rolesSnapshot = await getDocs(rolesCollection);
        const rolesList = rolesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRoles(rolesList);

        // Fetch employee details
        const employeeDocRef = doc(db, "Employees", id);
        const employeeSnapshot = await getDoc(employeeDocRef);

        if (employeeSnapshot.exists()) {
          const employeeData = employeeSnapshot.data();
          setRoleId(employeeData.roleId || ""); // Set roleId if available
          setValue("name", employeeData.name || "");
          setValue("email", employeeData.email || "");
          setValue("position", employeeData.position || "");
          setValue("employeeAddress", employeeData.employeeAddress || "");
          setValue("nationalID", employeeData.nationalID || "");
          setValue("phoneNumber", employeeData.phoneNumber || "");
        } else {
          toast.error("Employee not found.");
          navigate("/employees");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch employee data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchRolesAndEmployee();
  }, [id, navigate, setValue]);

  const onSubmit = async (data) => {
    try {
      const employeeDocRef = doc(db, "Employees", id);
      await updateDoc(employeeDocRef, {
        ...data,
        roleId, // Ensure the roleId is included
      });
      toast.success("Employee updated successfully!");
      navigate("/employees");
    } catch (error) {
      console.error("Error updating employee:", error);
      toast.error("Failed to update employee.");
    }
  };

  const roleOptions = roles.map((role) => ({
    value: role.id,
    label: role.role,
  }));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div>
        <h1  className="font-bold text-xl text-secondaryColor mb-6">تحديث الموظف</h1>
      </div>
      <div className="rounded-lg shadow-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <div className="flex justify-between gap-6">
              <div className="w-full">
                <InputField
                   label="اسم الموظف"
                  error={errors.name?.message}
                  register={register}
                  name="name"
                   placeholder="أدخل اسم الموظف"
                />
              </div>
              <div className="w-full">
                <InputField
                  label="البريد الإلكتروني للموظف"
                  error={errors.email?.message}
                  register={register}
                  name="email"
                  placeholder="أدخل البريد الإلكتروني للموظف"
                />
              </div>
            </div>

            <div className="flex justify-between gap-6">
              <div className="w-full">
                <InputField
                  label="مسمى وظيفي"
                  error={errors.position?.message}
                  register={register}
                  name="position"
                 placeholder="أدخل المسمى الوظيفي"
                />
              </div>

              <div className="w-full">
                <InputField
                   label="عنوان الموظف"
                  error={errors.employeeAddress?.message}
                  register={register}
                  name="employeeAddress"
                   placeholder="أدخل عنوان الموظف"
                />
              </div>
            </div>

            <div className="flex justify-between gap-6">
              <div className="w-full">
                <InputField
                  label="الهوية الوطنية"
                  error={errors.nationalID?.message}
                  register={register}
                 placeholder="أدخل الرقم القومي"
                  name="nationalID"
                />
              </div>
              <div className="w-full">
                <InputField
                  label="رقم التليفون"
                  error={errors.phoneNumber?.message}
                  register={register}
                  name="phoneNumber"
                   placeholder="أدخل رقم الهاتف"
                />
              </div>
            </div>

            <div className="flex justify-between gap-6">
              <div className="w-full">
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <SearchableDropdown
                      options={roleOptions}
                      selectedValue={roleId}
                      setSelectedValue={(value) => {
                        setRoleId(value);
                        field.onChange(value);
                      }}
                      placeholder="حدد دورًا"
                    />
                  )}
                />
              </div>
            </div>

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
             تحديث الموظف
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateEmployee;
