import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { doc, updateDoc, getDoc, getDocs, collection } from "firebase/firestore";
import { Controller, useForm } from "react-hook-form";
import SearchableDropdown from "../../components/fields/SearchableDropdown";
import InputField from "../../components/fields/TextInput";
import { useParams, useNavigate } from "react-router-dom";

const UpdateServiceProvider = () => {
  const { id } = useParams(); // Get the service provider ID from the URL
  const {
    control,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm(); // react-hook-form support
  const [services, setServices] = useState([]);
  const [selectedProgramType, setSelectedProgramType] = useState("");
  const navigate = useNavigate();

  // Fetch services for the dropdown
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesCollectionRef = collection(db, "Services");
        const servicesSnapshot = await getDocs(servicesCollectionRef);
        const servicesData = servicesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServices(servicesData);
      } catch (error) {
        console.error("Error fetching services:", error);
        toast.error("Failed to fetch services");
      }
    };

    fetchServices();
  }, []);

  // Fetch service provider details
  useEffect(() => {
    const fetchServiceProvider = async () => {
      try {
        const docRef = doc(db, "ServicesProvider", id);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setValue("name", data.name);
          setValue("email", data.email);
          setValue("phoneNumber", data.phoneNumber);
          setValue("address", data.address);

          // Set the selected service type based on the program name
          const selectedService = services.find(
            (service) => service.servicename === data.programName
          );
          if (selectedService) {
            setSelectedProgramType(selectedService.id);
          }
        } else {
          toast.error("Service provider not found");
          navigate("/services-provider");
        }
      } catch (error) {
        console.error("Error fetching service provider details:", error);
        toast.error("Failed to fetch service provider details");
      }
    };

    fetchServiceProvider();
  }, [id, setValue, navigate, services]);

  const programOptions = services.map((program) => ({
    value: program.id,
    label: program.servicename,
  }));

  // Handle form submission
  const onSubmit = async (data) => {
    try {
      const selectedProgram = services.find(
        (program) => program.id === selectedProgramType
      );
      const programName = selectedProgram ? selectedProgram.servicename : "";

      const docRef = doc(db, "ServicesProvider", id);
      await updateDoc(docRef, {
        ...data,
        programName,
      });

      toast.success("Service provider updated successfully");
      navigate("/services-provider");
    } catch (error) {
      console.error("Error updating service provider:", error);
      toast.error("Failed to update service provider");
    }
  };

  return (
    <div>
      <h1 className="font-bold text-xl text-secondaryColor mb-6">
      تحديث مزود الخدمة
      </h1>

      <div className="rounded-lg shadow">
        <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
          {/* Program Type Dropdown */}
          <div >
            <Controller
              name="servicetype"
              control={control}
              render={({ field }) => (
                <SearchableDropdown
                  options={programOptions}
                  selectedValue={selectedProgramType} // Display the selected value
                  setSelectedValue={(value) => {
                    setSelectedProgramType(value);
                    field.onChange(value); // Ensure the value is passed to react-hook-form
                  }}
                   placeholder="اختر نوع الخدمة"
                />
              )}
            />
          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              {/* Name Field */}
              <InputField
                 label="اسم"
                error={errors.name?.message}
                register={register}
                name="name"
                placeholder="أدخل الاسم"
              />
            </div>

            <div className="w-full">
              {/* Email Field */}
              <InputField
                label="بريد إلكتروني"
                error={errors.email?.message}
                register={register}
                name="email"
               placeholder="أدخل البريد الإلكتروني"
              />
            </div>
          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              {/* Phone Number Field */}
              <InputField
                label="رقم التليفون"
                error={errors.phoneNumber?.message}
                register={register}
                name="phoneNumber"
                 placeholder="أدخل رقم الهاتف"
              />
            </div>

            <div className="w-full">
              {/* Address Field */}
              <InputField
                label="عنوان"
                error={errors.address?.message}
                register={register}
                name="address"
                placeholder="أدخل العنوان"
              />
            </div>
          </div>

          {/* Submit Button */}
          <button
            onClick={handleSubmit(onSubmit)}
            className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
          >
           تحديث مزود الخدمة
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateServiceProvider;
