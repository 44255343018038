import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Importing icons
import { updateDoc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";
import { toast } from "react-toastify";
import DocumentUploadButton from "../../components/DocumentUploadButton";


const ViewSingleLoanApplication = () => {
  const { id } = useParams();
  const [loanApplication, setLoanApplication] = useState(null);
  const [expandedBankId, setExpandedBankId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [newComments, setNewComments] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  useEffect(() => {
    const fetchLoanData = async () => {
      try {
        setLoading(true);
        const docRef = doc(db, "LoanApplication", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const loanData = docSnap.data();
          setLoanApplication(loanData);
          console.log("loanData", loanData);
        } else {
          setError("Loan application not found.");
        }
      } catch (error) {
        console.error("Error fetching loan application:", error);
        setError("Error fetching loan application.");
      } finally {
        setLoading(false);
      }
    };

    if (id) fetchLoanData();
  }, [id]);

  const columnsdocuments = [
    {
      field: "documentName",
      headerName: "Document Name",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "url",
      headerName: "View Document",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <a
          href={params.value}
          className="text-blue-500"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Document
        </a>
      ),
    },
  ];

  const toggleExpandBank = (bankId) => {
    setExpandedBankId(expandedBankId === bankId ? null : bankId);
  };

  const openModal = (bank) => {
    setSelectedBank(bank);
    setNewStatus(bank.status || "");
    setNewComments("");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBank(null);
    setNewStatus("");
    setNewComments("");
  };

  const handleDocumentSelection = (docName) => {
    setSelectedDocuments((prevDocs) =>
      prevDocs.includes(docName)
        ? prevDocs.filter((doc) => doc !== docName)
        : [...prevDocs, docName]
    );
  };

  const handleUpdateHistory = async () => {
    if (!selectedBank) {
      toast.error("No bank selected");
      return;
    }
  
    try {
      if (!loanApplication || !Array.isArray(loanApplication.bankApplications)) {
        throw new Error("Invalid loan application data");
      }
  
      // Create a deep copy of the current bank applications
      const updatedBankApplications = loanApplication.bankApplications.map((bank) => {
        if (bank.bank === selectedBank.bank) { // Changed from bankId to bank
          // Update the documents status if status is "Documents Rejected"
          const updatedLoanDocuments = bank.loanDocuments.map((doc) => ({
            ...doc,
            status:
              selectedDocuments.includes(doc.documentName) &&
              newStatus === "Documents Rejected"
                ? "Rejected"
                : doc.status,
          }));
  
          // Create new history entry
          const newHistoryEntry = {
            timestamp: new Date().toISOString(),
            status: newStatus,
            comments: newComments,
            rejectedDocuments:
              newStatus === "Documents Rejected" ? selectedDocuments : [],
          };
  
          return {
            ...bank,
            approvalStatus: newStatus,
            loanDocuments: updatedLoanDocuments,
            history: Array.isArray(bank.history) 
              ? [...bank.history, newHistoryEntry]
              : [newHistoryEntry],
          };
        }
        return bank;
      });
  
      // Update Firestore
      const loanDocRef = doc(db, "LoanApplication", id);
      await updateDoc(loanDocRef, {
        bankApplications: updatedBankApplications,
      });
  
      // Update local state
      setLoanApplication((prev) => ({
        ...prev,
        bankApplications: updatedBankApplications,
      }));
  
      await logUserActivity("User updated the History Status and Loan Documents");
      toast.success("History status and loan documents updated successfully!");
  
      closeModal();
    } catch (error) {
      console.error("Error updating history:", error);
      toast.error(error.message || "Error updating history");
    }
  };

  const columnsHistory = [
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "header",
    },
  ];

  const [isCompleted, setIsCompleted] = useState(false);

  // Initialize checkbox state when loan application data is loaded
  useEffect(() => {
    if (loanApplication) {
      setIsCompleted(loanApplication.status === "Completed");
    }
  }, [loanApplication]);

  // Handle status update
  const handleStatusChange = async (e) => {
    const newStatus = e.target.checked ? "Completed" : "In Progress";

    try {
      const loanDocRef = doc(db, "LoanApplication", id);
      await updateDoc(loanDocRef, {
        status: newStatus,
      });

      // Update local state
      setLoanApplication((prev) => ({
        ...prev,
        status: newStatus,
      }));

      setIsCompleted(e.target.checked);

      // Log the activity
      await logUserActivity(`User marked loan application as ${newStatus}`);

      toast.success(`Application marked as ${newStatus}`);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update application status");
      // Revert checkbox state on error
      setIsCompleted(!e.target.checked);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-10 rounded-lg shadow-lg">
      <h1 className="text-2xl text-secondaryColor">Loan Application Details</h1>
      {loanApplication ? (
        <div className="mt-4 mb-2 shadow-custom p-4 rounded">
          <div className="flex justify-between">
            <p>
              <strong>Loan Program:</strong> {loanApplication.loanProgram}
            </p>
            <p>
              <strong>Total Loan Amount:</strong>{" "}
              {loanApplication.totalLoanAmount}
            </p>
          </div>
          <div className="flex justify-between">
            <p>
              <strong>UserName:</strong> {loanApplication.userName}
            </p>
            <p>
              <strong>Status:</strong>{" "}
              <span
                className={`font-medium ${
                  loanApplication.status === "Completed"
                    ? "text-green-600"
                    : "text-blue-600"
                }`}
              >
                {loanApplication.status}
              </span>
            </p>
          </div>

          {/* Display only the object names from programSpecificDetails */}

          <div className="flex justify-end items-center mb-4">
            <div className="flex items-center space-x-4">
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={isCompleted}
                  onChange={handleStatusChange}
                  className="form-checkbox h-5 w-5 text-secondaryColor rounded border-gray-300 focus:ring-secondaryColor"
                />
                <span className="ml-2 text-gray-700">Mark as Completed</span>
              </label>
            </div>
          </div>
        </div>
      ) : (
        <p>No data available.</p>
      )}

      <h2 className="text-xl mt-4 text-secondaryColor">Bank Applications</h2>
      {loanApplication?.bankApplications?.map((bank, index) => (
        <div
          key={index}
          className="mt-4 p-4 border rounded shadow-md cursor-pointer"
          onClick={() => toggleExpandBank(bank.bank)}
        >
          <div className="flex justify-between items-center">
            <div>
              <p>
                <strong>Bank Name:</strong> {bank?.bank || "N/A"}
              </p>
              <p>
                <strong>Loan Amount:</strong> {bank.loanAmount}
              </p>
            </div>
            {/* Dropdown icon */}
            <div>
              {expandedBankId === bank.bank ? (
                <FaChevronUp className="text-gray-600 cursor-pointer" />
              ) : (
                <FaChevronDown className="text-gray-600 cursor-pointer" />
              )}
            </div>
          </div>

          {expandedBankId === bank.bank && (
            <div className="mt-4">
              <h3 className="text-lg font-semibold text-secondaryColor">
                Bank Details
              </h3>
              <p>
                <strong>Approval Status:</strong> {bank.approvalStatus}
              </p>
              {bank.assignedBankEmployee && (
                <div>
                  <h4 className="text-md font-semibold mt-2 text-secondaryColor">
                    Assigned Employee
                  </h4>
                  <p>
                    <strong>Name:</strong>{" "}
                    {bank.assignedBankEmployee.bankEmployeeName}
                  </p>
                  <p>
                    <strong>Email:</strong>{" "}
                    {bank.assignedBankEmployee.bankEmployeeEmail}
                  </p>
                  <p>
                    <strong>Contact:</strong>{" "}
                    {bank.assignedBankEmployee.contactNumber}
                  </p>
                </div>
              )}

              {/* Display Program Specific Details */}
              {bank.programSpecificDetails &&
              Object.keys(bank.programSpecificDetails).length > 0 ? (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold text-secondaryColor">
                    Program Specific Details
                  </h3>
                  {Object.entries(bank.programSpecificDetails).map(
                    ([key, detail]) => (
                      <div key={key} className="mt-2">
                        <h4 className="font-semibold">
                          {detail?.objectName || key}
                        </h4>
                        {Object.entries(detail)
                          .filter(
                            ([propKey]) =>
                              propKey !== "fields" && propKey !== "objectName"
                          )
                          .map(([propKey, propValue]) => (
                            <p key={propKey}>
                              <strong>{propKey}:</strong>{" "}
                              {Array.isArray(propValue) ? (
                                // If the value is an array, display each item with a dash
                                <span>
                                  <br />
                                  {propValue.map((item, index) => (
                                    <span key={index} className="ml-4">
                                      - {item}
                                      <br />
                                    </span>
                                  ))}
                                </span>
                              ) : (
                                // If not an array, display as before
                                propValue || "N/A"
                              )}
                            </p>
                          ))}
                      </div>
                    )
                  )}
                </div>
              ) : (
                <p className="text-gray-500 mt-2">
                  No program-specific details available for this bank
                  application.
                </p>
              )}

              {/* Display Documents Table */}
            {/* Display Documents Table */}
{bank.loanDocuments && bank.loanDocuments.length > 0 ? (
  <div className="mt-4">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-semibold text-secondaryColor">
        Documents
      </h3>
     
    </div>
    <DataGrid
      rows={bank.loanDocuments.map((doc, index) => ({
        id: index,
        documentName: doc.fileName,
        status: doc.status,
        url: doc.url,
      }))}
      columns={columnsdocuments}
      sx={{
        "& .header": {
          backgroundColor: "#214783",
          color: "#FFFFFF",
          fontWeight: "bold",
          fontSize: "16px",
        },
        "& .MuiDataGrid-cell": {
          backgroundColor: "#f4f4f4",
          color: "#000",
        },
      }}
      autoHeight
    />
  </div>
) : (
  <div className="mt-4">
    <div className="flex justify-between items-center">
      <p className="text-gray-500">
        No documents available for this bank application.
      </p>
      {bank.approvalStatus === "Document Required" && (
        <DocumentUploadButton 
          bank={bank}
          loanApplicationId={id}
          onUploadSuccess={(newDoc) => {
            setLoanApplication(prev => ({
              ...prev,
              bankApplications: prev.bankApplications.map(b => {
                if (b.bank === bank.bank) {
                  return {
                    ...b,
                    loanDocuments: [newDoc]
                  };
                }
                return b;
              })
            }));
          }}
        />
      )}
    </div>
  </div>
)}

              {bank.history && bank.history.length > 0 ? (
                <div className="mt-2">
                  <div className="flex justify-between">
                    <div>
                      <h3 className="text-lg font-semibold mt-4 text-secondaryColor">
                        Application History
                      </h3>
                    </div>
                    <div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent collapse toggle
                          openModal(bank);
                        }}
                        className="ml-4 my-4 px-3 py-1 text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded hover:bg-blue-600"
                      >
                        Update History
                      </button>
                    </div>
                  </div>
                  <DataGrid
                    rows={bank.history.map((doc, index) => ({
                      id: index, // Ensure a unique id for each row
                      status: doc.status,
                      comments: doc.comments,
                      date: doc.timestamp, // Pass the timestamp directly
                    }))}
                    columns={columnsHistory}
                    sx={{
                      "& .header": {
                        backgroundColor: "#214783",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: "16px",
                      },
                      "& .MuiDataGrid-cell": {
                        backgroundColor: "#f4f4f4",
                        color: "#000",
                      },
                    }}
                  />
                </div>
              ) : (
                <p className="text-gray-500">
                  No history available for this application.
                </p>
              )}
            </div>
          )}
        </div>
      ))}

      {/* Modal for Updating History */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center  justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Update History</h2>
            <label className="block mb-2">
              <span>Status</span>
              <select
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                className="w-full p-2 border rounded mt-1"
              >
                <option value="InProgress">InProgress</option>
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Application Criteria Match">
                  Application Criteria Match
                </option>
                <option value="Document Required">Document Required</option>
                <option value="Documents Rejected">Documents Rejected</option>
                <option value="Rejected">Rejected</option>
              </select>
            </label>

            {newStatus === "Documents Rejected" && (
              <div>
                <h4>Rejected Documents</h4>
                {selectedBank.loanDocuments.map((doc, index) => (
                  <div key={index}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedDocuments.includes(doc.documentName)}
                        onChange={() =>
                          handleDocumentSelection(doc.documentName)
                        }
                      />
                      {doc.fileName}
                    </label>
                  </div>
                ))}
              </div>
            )}

            <label className="block mb-2">
              <span>Comments</span>
              <textarea
                value={newComments}
                onChange={(e) => setNewComments(e.target.value)}
                className="w-full p-2 border rounded mt-1"
              />
            </label>
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="mr-4 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateHistory}
                className="px-4 py-2 bg-gradient-to-r from-secondaryColor to-primaryColor text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewSingleLoanApplication;
