import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import InputField from "../../components/fields/TextInput";
import SelectField from "../../components/fields/SelectField";
import { logUserActivity } from "../../services/logUserActivity";

const bankSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Bank Name is required" })
    .max(30, { message: "Max 30 characters" }),
  headquarters: z
    .string()
    .min(1, { message: "Location is required" })
    .max(300, { message: "Max 300 characters" }),
  contactNumber: z
    .string()
    .min(1, { message: "Contact Number is required" })
    .max(15, { message: "Max 15 characters" }),
  type: z.enum(["bank", "corporate"], {
    message: "Type must be either Bank or Corporate",
  }),
});

const UpdateBank = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ resolver: zodResolver(bankSchema) });
  const navigate = useNavigate();
  const { id } = useParams(); // Get bank ID from URL

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const bankRef = doc(db, "Banks", id);
        const bankSnapshot = await getDoc(bankRef);

        if (bankSnapshot.exists()) {
          const bankData = bankSnapshot.data();
          setValue("name", bankData.name);
          setValue("headquarters", bankData.headquarters);
          setValue("contactNumber", bankData.contactNumber);
          setValue("type", bankData.type);
        } else {
          toast.error("Bank not found");
          navigate("/banks");
        }
      } catch (error) {
        console.error("Error fetching bank details:", error);
        toast.error("Failed to fetch bank details");
      }
    };

    fetchBankDetails();
  }, [id, navigate, setValue]);

  const onSubmit = async (data) => {
    try {
      const bankRef = doc(db, "Banks", id);
      await updateDoc(bankRef, {
        name: data.name,
        headquarters: data.headquarters,
        contactNumber: data.contactNumber,
        type: data.type,
      });

      toast.success("Bank updated successfully");
      logUserActivity("User Updated Bank");
      reset();
      navigate("/banks");
    } catch (error) {
      console.error("Error updating bank:", error);
      toast.error("Failed to update bank");
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl text-secondaryColor mb-6">تحديث البنك</h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <div className="flex justify-between gap-6">
              <div className="w-full">
                <InputField
                   label="اسم البنك"
                  error={errors.name?.message}
                  register={register}
                  name="name"
                />
              </div>

              <div className="w-full">
                <InputField
                   label="موقع المقر"
                  error={errors.headquarters?.message}
                  register={register}
                  name="headquarters"
                />
              </div>
            </div>

            <div className="flex justify-between gap-6">
              <div className="w-full">
                <SelectField
                   label="يكتب"
                  name="type"
                  options={[
                    { id: "bank", name: "Bank" },
                    { id: "corporate", name: "Corporate" },
                  ]}
                  register={register}
                  error={errors.type?.message}
                />
              </div>
            </div>

            <InputField
               label="رقم الاتصال"
              error={errors.contactNumber?.message}
              register={register}
              name="contactNumber"
            />

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              تحديث البنك
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateBank;
