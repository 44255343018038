import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import SelectField from "../../components/fields/SelectField";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";
import SearchableDropdown from "../../components/fields/SearchableDropdown";

const clientSchema = z
  .object({
    type: z.enum(["individual", "corporate"], {
      message: "Type must be either Individual or Corporate",
    }),
    name: z.string().min(1, { message: "Client Name is required" }).max(50),
    corporateName: z.string().optional(),
    commercialRegistrationNumber: z.string().optional(),
    age: z.string().nullable(), // Validation moved to superRefine
    employmentStatus: z.string().nullable(), // Validation moved to superRefine
    monthlyIncome: z.string().nullable(), // Validation moved to superRefine
    contact: z.string().min(10, { message: "Contact number is required" }),
    nationalID: z.string().min(1, { message: "National ID is required" }),
    address: z.string().min(1, { message: "Address is required" }),
    email: z.string().optional(),
    existingLoans: z
      .string()
      .optional()
      .transform((val) =>
        val ? val.split(",").map((loanId) => loanId.trim()) : []
      ),
    commissionRate: z
      .string()
      .min(1, { message: "Commission rate is required" })
      .regex(/^\d+(\.\d{1,2})?$/, { message: "Invalid commission rate format" }),
    admincommissionRate: z
      .string()
      .min(1, { message: "Admin Commission rate is required" })
      .regex(/^\d+(\.\d{1,2})?$/, { message: "Invalid commission rate format" }),
  })
  .superRefine((data, ctx) => {
    if (data.type === "individual") {
      if (!data.age || data.age.trim() === "") {
        ctx.addIssue({
          path: ["age"],
          message: "Age is required for individual clients",
        });
      }
      if (!data.employmentStatus) {
        ctx.addIssue({
          path: ["employmentStatus"],
          message: "Employment status is required for individual clients",
        });
      }
      if (!data.monthlyIncome || data.monthlyIncome.trim() === "") {
        ctx.addIssue({
          path: ["monthlyIncome"],
          message: "Monthly income is required for individual clients",
        });
      }
    }

    if (data.type === "corporate") {
      if (!data.corporateName) {
        ctx.addIssue({
          path: ["corporateName"],
          message: "Corporate Name is required for corporate clients",
        });
      }
      if (!data.commercialRegistrationNumber) {
        ctx.addIssue({
          path: ["commercialRegistrationNumber"],
          message:
            "Commercial Registration Number is required for corporate clients",
        });
      }
    }
  });





const AddClient = () => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(clientSchema),
    defaultValues: {
      type: "individual",
    },
  });

  const clientType = watch("type");
  console.log("Client Type:", clientType);

  const [brokers, setBrokers] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState("");
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBrokerCompnay = async () => {
      try {
        const brokersCollectionRef = collection(db, "BrokerCompany");
        const brokerSnapshot = await getDocs(brokersCollectionRef);
        const brokersData = brokerSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBrokers(brokersData);
      } catch (error) {
        console.error("Error fetching brokers:", error);
        toast.error("Failed to fetch brokers.");
      }
    };

    const fetchClients = async () => {
      try {
        const clientsCollectionRef = collection(db, "Clients");
        const clientSnapshot = await getDocs(clientsCollectionRef);
        const clientsData = clientSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClients(clientsData);
      } catch (error) {
        console.error("Error fetching clients:", error);
        toast.error("Failed to fetch clients.");
      }
    };

    fetchBrokerCompnay();
    fetchClients();
  }, []);

  const onSubmit = async (data) => {
    try {
        if (!selectedBroker) {
            toast.error("Please select a broker.");
            return;
        }

        const broker = brokers.find((b) => b.id === selectedBroker);
        const brokerName = broker ? broker.companyName : "";

        const highestClientId = clients.reduce((max, client) => {
            if (client.clientId && client.clientId.includes("-")) {
                const clientSerialNo = parseInt(client.clientId.split("-")[1], 10);
                return clientSerialNo > max ? clientSerialNo : max;
            }
            return max;
        }, 0);

        const newClientId = `Client-${(highestClientId + 1).toString().padStart(4, "0")}`;

        const formattedData = {
            type: data.type,
            name: data.name,
            ...(data.type === "corporate" && {
                corporateName: data.corporateName,
                commercialRegistrationNumber: data.commercialRegistrationNumber,
            }),
            ...(data.type === "individual" && {
                age: data.age,
                employmentStatus: data.employmentStatus,
                monthlyIncome: data.monthlyIncome,
            }),
            contact: data.contact,
            nationalID: data.nationalID,
            address: data.address,
            commissionRate: data.commissionRate,
            admincommissionRate: data.admincommissionRate,
            brokerId: selectedBroker,
            brokerName,
            clientId: newClientId,
            ...(data.email && { email: data.email }),
        };

        console.log("Formatted Data:", formattedData);

        const docRef = await addDoc(collection(db, "Clients"), formattedData);
        console.log("Document written with ID: ", docRef.id);
        toast.success("Client added successfully!");
        logUserActivity("User Added Client");
        navigate("/clients");
    } catch (error) {
        console.error("Error adding client: ", error);
        toast.error("Failed to add client.");
    }
};


  const brokerOptions = brokers.map((broker) => ({
    value: broker.id,
    label: broker.companyName,
  }));

  return (
    <>
      <div>
        <h1 className="font-bold text-xl text-secondaryColor mb-6">
          Create Client
        </h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <div className="flex justify-between gap-6">
              <div className="w-full">
                <Controller
                  control={control}
                  name="type"
                  defaultValue="individual"
                  render={({ field }) => (
                    <SelectField
                      label="Type"
                      options={[
                        { id: "individual", name: "Individual" },
                        { id: "corporate", name: "Corporate" },
                      ]}
                      register={register}
                      name="type"
                      error={errors.type?.message}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
              <div className="w-full">
                <InputField
                  label="Client Name"
                  error={errors.name?.message}
                  register={register}
                  name="name"
                  placeholder="Enter client name"
                />
              </div>
            </div>

            {clientType === "corporate" ? (
              <div className="flex justify-between gap-6">
                <div className="w-full">
                  <InputField
                    label="Corporate Name"
                    error={errors.corporateName?.message}
                    register={register}
                    name="corporateName"
                    placeholder="Enter corporate name"
                  />
                </div>
                <div className="w-full">
                  <InputField
                    label="Commercial Registration Number"
                    error={errors.commercialRegistrationNumber?.message}
                    register={register}
                    name="commercialRegistrationNumber"
                    placeholder="Enter commercial registration number"
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="flex justify-between gap-6">
                  <div className="w-full">
                    <InputField
                      label="Age"
                      error={errors.age?.message}
                      register={register}
                      name="age"
                      type="number"
                      placeholder="Enter client age"
                      required={clientType === "individual"}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="employmentStatus"
                      render={({ field }) => (
                        <SelectField
                          label="Employment Status"
                          options={[
                            { id: "Governmental", name: "Governmental" },
                            {
                              id: "Semi-Governmental",
                              name: "Semi-Governmental",
                            },
                            { id: "Military", name: "Military" },
                            {
                              id: "Retired (over 60 - under 60)",
                              name: "Retired (over 60 - under 60)",
                            },
                            { id: "Non-Saudi", name: "Non-Saudi" },
                            { id: "Business owner", name: "Business owner" },
                            { id: "Freelance", name: "Freelance" },
                            {
                              id: "Company employee (with agreement - or not)",
                              name: "Company employee (with agreement - or not)",
                            },
                          ]}
                          register={register}
                          name="employmentStatus"
                          error={errors.employmentStatus?.message}
                          onChange={field.onChange}
                          disabled={false}
                          required={clientType === "individual"}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex justify-between gap-6">
                  <div className="w-full">
                    <InputField
                      label="Monthly Income"
                      error={errors.monthlyIncome?.message}
                      register={register}
                      name="monthlyIncome"
                      type="number"
                      placeholder="Enter monthly Income"
                      required={clientType === "individual"}
                    />
                  </div>
                  <div className="w-full"></div>
                </div>
              </>
            )}

            <div className="flex justify-between gap-6">
              <div className="w-full">
                <InputField
                  label="Contact Number"
                  error={errors.contact?.message}
                  register={register}
                  name="contact"
                  type="tel"
                  placeholder="Enter contact number"
                />
              </div>
              <div className="w-full">
                <InputField
                  label="Email Address"
                  error={errors.email?.message}
                  register={register}
                  name="email"
                  type="email"
                  placeholder="Enter email address"
                />
              </div>
            </div>

            <div className="flex justify-between gap-6">
              <div className="w-full">
                <InputField
                  label="National ID"
                  error={errors.nationalID?.message}
                  register={register}
                  name="nationalID"
                  type="text"
                  placeholder="Enter National ID"
                />
              </div>
              <div className="w-full">
                <InputField
                  label="Address"
                  error={errors.address?.message}
                  register={register}
                  name="address"
                  type="text"
                  placeholder="Enter address"
                />
              </div>
            </div>

            <div className="flex justify-center gap-6">
              <div className="w-full">
                <InputField
                  label="Admin Commission Rate (%)"
                  error={errors.admincommissionRate?.message}
                  register={register}
                  name="admincommissionRate"
                  type="number"
                  placeholder="Enter admin commission rate"
                />
              </div>
              <div className="w-full">
                <InputField
                  label="Broker Commission Rate (%)"
                  error={errors.commissionRate?.message}
                  register={register}
                  name="commissionRate"
                  type="number"
                  placeholder="Enter broker commission rate"
                />
              </div>
            </div>

            <div className="w-full">
              <Controller
                name="broker"
                control={control}
                render={({ field }) => (
                  <SearchableDropdown
                    options={brokerOptions}
                    selectedValue={selectedBroker}
                    setSelectedValue={(value) => {
                      setSelectedBroker(value);
                      field.onChange(value);
                    }}
                    placeholder="Select a Broker"
                  />
                )}
              />
            </div>

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              Add Client
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddClient;
