import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";
import CustomDataTable from "../../components/CustomDataTable";

const ViewProgramType = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("permissions")) || {};

  const canAdd = permissions.ProgramTypeAdd || false;
  const canEdit = permissions.ProgramTypeUpdate || false;
  const canDelete = permissions.ProgramTypeDelete || false;


  // Define columns without the "Action" column initially
  const columns = [
    {
      field: "serialNo",
      headerName: "الرقم التسلسلي",
      flex: 1,
      headerClassName: "header",
     
    },
    {
      field: "programtype",
      headerName: "نوع البرنامج",
      flex: 1,
      headerClassName: "header",
     
    },
  ];

  // Conditionally add the "Action" column if either canEdit or canDelete is true
  if (canEdit || canDelete) {
    columns.push({
      field: "action",
      headerName: "الإجراءات",
      flex: 1,
      headerClassName: "header",
      
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full">
          {canEdit && (
            <Link
              to={`/update-program-type/${params.row.id}`}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
            >
              <FaPencilAlt className="text-white h-4 w-4" />
            </Link>
          )}
          {canDelete && (
            <div
              onClick={() => handleDelete(params.row.id)}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
            >
              <FaTrash className="text-white h-4 w-4" />
            </div>
          )}
        </div>
      ),
    });
  }

  const fetchProgramTypes = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "ProgramType"));
      const programTypes = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        serialNo: index + 1,
        ...doc.data(),
      }));
      setData(programTypes);
      logUserActivity("User Viewed Program Types");
    } catch (error) {
      console.error("Error fetching data: ", error);
      setError("Failed to load program types.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProgramTypes();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this program type?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "ProgramType", id));
        setData((prevData) => prevData.filter((item) => item.id !== id));
        logUserActivity("User Deleted Program Type");
      } catch (error) {
        console.error("Error deleting data: ", error);
        setError("Failed to delete the program type.");
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl text-secondaryColor">أنواع البرامج</h1>
        {canAdd && (
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 py-2 gap-2 items-center text-sm font-semibold">
            <Link to="/add-program-type">إضافة نوع البرنامج</Link>
          </button>
        )}
      </div>

      <CustomDataTable
        rows={data}
        columns={columns}
        loading={loading}
        error={error}
        noDataMessage="لم يتم العثور على بيانات"
        loadingMessage="جاري التحميل..."
      />
    </div>
  );
};

export default ViewProgramType;
