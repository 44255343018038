import React, { useEffect, useState } from "react";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { FaEye, FaPencilAlt, FaTrash } from "react-icons/fa";
import { logUserActivity } from "../../services/logUserActivity";
import CustomDataTable from "../../components/CustomDataTable";

const ViewLoanData = () => {
  const [loanPrograms, setLoanPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("permissions")) || {};
  const canAdd = permissions.LoanProgramAdd || false;
  const canEdit = permissions.LoanProgramUpdate || false;
  const canDelete = permissions.LoanProgramDelete || false;

  const fetchLoanPrograms = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "loanPrograms"));
      const programs = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          starting_date: data.loan_term_details?.starting_date || "N/A", // Extract starting_date
          ending_date: data.loan_term_details?.ending_date || "N/A", // Extract ending_date
        };
      });
      setLoanPrograms(programs);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching loan programs:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoanPrograms();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this data?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "loanPrograms", id)); // Delete employee from Firestore
        setLoanPrograms(loanPrograms.filter((item) => item.id !== id)); // Update state to remove the deleted item
        logUserActivity("User Deleted Loan Program");
      } catch (error) {
        console.error("Error deleting data: ", error);
        setError("Failed to delete data."); // Handle error
      }
    }
  };

  const baseColumns = [
    {
      field: "loan_program_name",
      headerName: "اسم البرنامج",
      flex: 1,
      headerClassName: "header",
   
      
    },
    {
      field: "program_category",
      headerName: "فئة",
      flex: 1,
      headerClassName: "header",
   
    },
    {
      field: "starting_date",
      headerName: "تاريخ البدء",
      flex: 1,
      headerClassName: "header",
     
    },
    {
      field: "ending_date",
      headerName: "تاريخ الانتهاء",
      flex: 1,
      headerClassName: "header",
      
    },
  ];

  const actionColumn = {
    field: "actions",
    headerName: "الإجراءات",
    flex: 1,
    headerClassName: "header",
  
    renderCell: (params) => (
      <div className="flex items-center gap-2 h-full">
        {/* View Icon */}
        <Link
          to={`/view-single-loan/${params.row.id}`}
          className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
        >
          <FaEye className="text-white cursor-pointer h-4 w-4" />
        </Link>

        {/* Edit Icon */}
        {canEdit && (
          <Link
            to={`/update-loan/${params.row.id}`} // Adjust path accordingly
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
          </Link>
        )}

        {/* Delete Icon */}
        {canDelete && (
          <div
            onClick={() => handleDelete(params.row.id)}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
          >
            <FaTrash className="text-white h-4 w-4" />
          </div>
        )}
      </div>
    ),
  };

  // Conditionally include the Actions column
  const columns = canEdit || canDelete ? [...baseColumns, actionColumn] : baseColumns;

  return (
    <div style={{ height: 400, width: "100%", padding: "20px" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl text-secondaryColor">برامج القروض</h1>
        {canAdd && (
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
            <Link to="/add-loan">إضافة برنامج القرض</Link>
          </button>
        )}
      </div>

    
      <CustomDataTable
        rows={loanPrograms}
        columns={columns}
        loading={loading}
        error={error}
        noDataMessage="لم يتم العثور على بيانات"
        loadingMessage="جاري التحميل..."
      />
    </div>
  );
};

export default ViewLoanData;
