import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import SelectField from "../../components/fields/SelectField";
import { logUserActivity } from "../../services/logUserActivity";

// Zod Schema for validation
const referralCompanySchema = z.object({
  companyName: z
    .string()
    .min(1, { message: "Company Name is required" })
    .max(50, { message: "Company Name cannot exceed 50 characters" }),
  contactDetail: z
    .string()
    .min(1, { message: "Contact Detail is required" })
    .max(200, { message: "Contact Detail cannot exceed 200 characters" }),
  contactPersonName: z
    .string()
    .min(1, { message: "Contact Person Name is required" })
    .max(50, { message: "Contact Person Name cannot exceed 50 characters" }),
  contactEmail: z
    .string()
    .email({ message: "Invalid email address" })
    .optional(),
  type: z.enum(["individual", "corporate"], {
    message: "Type must be either Individual or Corporate",
  }),
});

const UpdateBrokerCompany = () => {
  const { id } = useParams(); // Get the broker company ID from the URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(referralCompanySchema),
  });

  useEffect(() => {
    const fetchBrokerCompany = async () => {
      try {
        const docRef = doc(db, "BrokerCompany", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          reset(docSnap.data()); // Populate form with the existing data
        } else {
          toast.error("Broker company not found.");
          navigate("/broker-company");
        }
      } catch (error) {
        console.error("Error fetching broker company:", error);
        toast.error("Failed to fetch broker company data.");
        navigate("/broker-company");
      } finally {
        setLoading(false);
      }
    };

    fetchBrokerCompany();
  }, [id, navigate, reset]);

  const onSubmit = async (data) => {
    try {
      const docRef = doc(db, "BrokerCompany", id);
      await updateDoc(docRef, data);
      toast.success("Broker company updated successfully!");
      logUserActivity("User Updated Broker Company");
      navigate("/broker-company");
    } catch (error) {
      console.error("Error updating broker company:", error);
      toast.error("Failed to update broker company.");
    }
  };

  if (loading) {
    return <div>تحميل...</div>;
  }

  return (
    <>
      <div>
        <h1 className="font-bold text-xl text-secondaryColor mb-6">
        تحديث شركة الوسيط
        </h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <div className="flex justify-between gap-6">
              <div className="w-full">
                <InputField
                 label="اسم الشركة"
                  name="companyName"
                  register={register}
                  error={errors.companyName?.message}
                  placeholder="أدخل اسم الشركة"
                />
              </div>

              <div className="w-full">
                <InputField
                 label="تفاصيل الاتصال"
                  name="contactDetail"
                  register={register}
                  error={errors.contactDetail?.message}
                  placeholder="أدخل تفاصيل الاتصال"
                />
              </div>
            </div>

            <div className="flex justify-between gap-6">
              <div className="w-full">
                <InputField
                 label="اسم جهة الاتصال"
                  name="contactPersonName"
                  register={register}
                  error={errors.contactPersonName?.message}
                   placeholder="أدخل اسم جهة الاتصال"
                />
              </div>
              <div className="w-full">
                <InputField
                   label="البريد الإلكتروني للاتصال"
                  name="contactEmail"
                  type="email"
                  register={register}
                  error={errors.contactEmail?.message}
                placeholder="أدخل البريد الإلكتروني لجهة الاتصال"
                />
              </div>
            </div>

            <div className="flex justify-between gap-6">
              <div className="w-full">
                <SelectField
                  label="يكتب"
                  name="type"
                  options={[
                    { id: "individual", name: "Individual" },
                    { id: "corporate", name: "Corporate" },
                  ]}
                  register={register}
                  error={errors.type?.message}
                />
              </div>
            </div>

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 mt-6"
            >
             تحديث شركة الوسيط
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateBrokerCompany;
