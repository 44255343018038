import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash, FaPencilAlt, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import CustomDataTable from "../../components/CustomDataTable";

const ViewClients = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const permissions = JSON.parse(localStorage.getItem("permissions")) || [];

  const canAdd = permissions.ClientAdd || false;
  const canEdit = permissions.ClientUpdate || false;
  const canDelete = permissions.ClientDelete || false;
  const canView = permissions.ClientView || false;

  const baseColumns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 1,
      headerClassName: "header",
     
    },
    {
      field: "clientId",
      headerName: "Client ID",
      flex: 1,
      headerClassName: "header",
     
    },
    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
      headerClassName: "header",
     
    },
  
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      headerClassName: "header",
     
    },
 
  ];

  const actionColumn = {
    field: "action",
    headerName: "Action",
    flex: 1,
    headerClassName: "header",
   
    renderCell: (params) => (
      <div className="flex items-center gap-2 h-full">
        {canView && (
          <Link
            to={`/view-client/${params.row.nationalID}`}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <FaEye className="text-white cursor-pointer h-4 w-4" />
          </Link>
        )}
        {canEdit && (
          <Link
            to={`/update-client/${params.row.id}`}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
          </Link>
        )}
        {canDelete && (
          <div
            onClick={() => handleDelete(params.row.id)}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
          >
            <FaTrash className="text-white h-4 w-4" />
          </div>
        )}
      </div>
    ),
  };

  const columns = [...baseColumns, ...(canEdit || canDelete ? [actionColumn] : [])];

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Clients"));
        const clients = querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1,
          ...doc.data(),
        }));
        setData(clients);
        console.log("-----", clients);
      } catch (error) {
        console.error("Error fetching clients: ", error);
        logUserActivity("User Viewed Clients");
        setError("Failed to load clients.");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this client?");
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "Clients", id));
        setData(data.filter((item) => item.id !== id));
        toast.success("Client deleted successfully!");
        logUserActivity("User Deleted Client");
      } catch (error) {
        console.error("Error deleting client data: ", error);
        setError("Failed to delete client data.");
        toast.error("Failed to delete client data.");
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-bold text-xl text-secondaryColor">جميع العملاء</h1>
        </div>

        <div>
          {canAdd && (
            <Link
              to="/add-client"
              className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer"
            >
              إضافة عميل
            </Link>
          )}
        </div>
      </div>

  
      <CustomDataTable
        rows={data}
        columns={columns}
        loading={loading}
        error={error}
        noDataMessage="لم يتم العثور على بيانات"
        loadingMessage="جاري التحميل..."
      />
      
    </div>
  );
};

export default ViewClients;
